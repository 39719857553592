import { IOS, ANDROID } from 'lib/platform';

const ios = [
  { label: 'iPhone SE (3rd gen)', value: '375x667' },
  { label: 'iPhone 12/13/14', value: '390x844' },
  { label: 'iPhone 15/16 & 14/15 Pro', value: '393x852', isDefault: true },
  { label: 'iPhone 15/16 Plus & 14/15 Pro Max', value: '430x932' },
  { label: 'iPhone 16 Pro', value: '402x874' },
  { label: 'iPhone 16 Pro Max', value: '440x956' },
  { label: 'iPad Mini', value: '744x1133', isTablet: true },
  { label: 'iPad 11" & 10th gen', value: '820x1180', isTablet: true },
  { label: 'iPad Air 13"', value: '1024x1366', isTablet: true },
  { label: 'iPad Pro 11"', value: '834x1210', isTablet: true },
  { label: 'iPad Pro 13"', value: '1032x1376', isTablet: true },
];

const android = [
  { label: 'Google Pixel 6/7/8', value: '412x915' },
  { label: 'Google Pixel 9', value: '412x923', isDefault: true },
  { label: 'Google Pixel 9 Pro', value: '384x855' },
  { label: 'Google Pixel 9 Pro XL', value: '411x914' },
  { label: 'Samsung Galaxy S23/S23+/S24', value: '360x780' },
  { label: 'Samsung Galaxy S24+', value: '400x880' },
  { label: 'Samsung Galaxy S23 Ultra/S24 Ultra', value: '480x1029' },
  { label: 'Galaxy Tab S10', value: '933x584', isTablet: true },
  { label: 'Galaxy Tab S10+/Ultra', value: '987x616', isTablet: true },
];

export const deviceOptions = {
  [IOS]: ios,
  [ANDROID]: android,
};

export const viewportOptions = Object.values(deviceOptions)
  .flat()
  .map(({ value }) => value);
