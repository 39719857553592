import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { Accordion } from '@appcues/sonar';
import { FieldSet, Label } from 'ext/components/ui';
import AngleInput from 'ext/components/AngleInput';
import { EditorShape } from 'entities/step-groups';
import { COLOR_MODES } from 'lib/user-preferences';
import { TRAIT_LABELS } from 'lib/trait';
import ColorInput from 'components/ColorInput';
import InputWithValidation from 'components/InputWithValidation';
import useAccordionClick from './use-accordion-click';
import {
  convertShadowFromSDK,
  convertShadowToSDK,
} from './drop-shadow-handler';
import { Controls, GroupedField } from './styled';

export default function DropShadow({
  editor,
  traitLabel,
  colorMode,
  onChange,
}) {
  const trackAccordion = useAccordionClick();
  const { style } = editor ?? {};
  const { shadow } = style ?? {};

  const [shadowValue, setShadowValue] = useState(convertShadowFromSDK(shadow));

  useEffect(() => {
    setShadowValue(convertShadowFromSDK(shadow));
  }, [shadow]);

  const handleChange = debounce((key, value) => {
    const newShadow = convertShadowToSDK({
      ...shadowValue,
      [key]: value,
    });

    onChange({
      style: {
        ...editor.style,
        shadow: newShadow,
      },
    });
  }, 500);

  return (
    <Accordion.Item value="drop-shadow">
      <Accordion.Header>
        <Accordion.Trigger
          onClick={() => trackAccordion(traitLabel, 'Drop shadow')}
        >
          Drop shadow
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <GroupedField>
              <Label htmlFor="drop-shadow-color">Color</Label>
              <ColorInput
                id="drop-shadow-color"
                label="Drop shadow color"
                color={
                  shadowValue?.color?.[colorMode] || shadowValue?.color?.light
                }
                onChange={value =>
                  handleChange('color', {
                    ...shadowValue?.color,
                    [colorMode]: value,
                  })
                }
              />
            </GroupedField>
          </FieldSet>

          <FieldSet>
            <InputWithValidation
              label="Distance"
              value={shadowValue?.distance}
              onChange={value => handleChange('distance', value)}
              max={100}
              min={0}
              range
            />
          </FieldSet>
          <FieldSet>
            <InputWithValidation
              label="Blur"
              value={shadowValue?.radius}
              onChange={value => handleChange('radius', value)}
              max={100}
              min={0}
              range
            />
          </FieldSet>

          <FieldSet>
            <AngleInput
              initialValue={shadowValue?.angle}
              throttleTime={0}
              onAngleChange={value => handleChange('angle', value)}
            />
          </FieldSet>
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

DropShadow.propTypes = {
  editor: EditorShape,
  traitLabel: PropTypes.oneOf(Object.values(TRAIT_LABELS)),
  colorMode: PropTypes.oneOf(COLOR_MODES),
  onChange: PropTypes.func,
};
