import styled from 'styled-components';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Icon, Switch } from '@appcues/sonar';
import { FieldSet, Select, Input } from 'ext/components/ui';

export const GroupedFieldSet = styled(FieldSet)`
  display: flex;
  gap: var(--spacing-regular);
`;

export const TrashIcon = styled(Icon).attrs({
  'aria-label': 'Delete property',
  icon: faTrash,
  role: 'button',
})`
  position: absolute;
  top: 12px;
  right: 8px;
  color: var(--foreground-secondary);
  cursor: pointer;

  && {
    width: 10px;
  }
`;

export const GroupedField = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-small);

  ${Input} {
    padding-right: 18px;
  }

  ${Select} {
    div[id$='placeholder'] {
      font-size: 13px;
      white-space: nowrap;
    }
  }

  ${Select},
  ${Input} {
    min-width: 100%;
  }
`;

export const SwitchLabel = styled(Switch.Label)`
  display: flex;
  align-items: center;
  gap: var(--spacing-x-small);
`;
