import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { Icon, Switch } from '@appcues/sonar';
import { Tooltip } from 'ext/components/ui';

const Label = styled.span`
  text-align: center;
`;

const FontIconWrapper = styled.span`
  padding: var(--spacing-x-small);

  ${Switch.Label} > & {
    display: inline;
  }

  ${Icon} {
    color: var(--foreground-brand);
  }
`;

export default function HelpLabel({ children, placement }) {
  const tooltipLabel = <Label>{children}</Label>;

  return (
    <Tooltip label={tooltipLabel} placement={placement}>
      <FontIconWrapper>
        <Icon aria-label="help" icon={faQuestionCircle} size="small" />
      </FontIconWrapper>
    </Tooltip>
  );
}

HelpLabel.propTypes = {
  children: PropTypes.node,
  placement: PropTypes.string,
};
