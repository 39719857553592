import React, { useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@appcues/sonar';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import useToggle from 'ext/lib/hooks/use-toggle';
import useClickOutside from 'ext/lib/hooks/use-click-outside';
import useEscape from 'ext/lib/hooks/use-escape';
import Fade from 'ext/components/Fade';
import {
  BrandMark,
  Menu,
  MenuHeading,
  List,
  ListItem,
} from 'ext/components/ui';
import { BottomMenuWrapper, MenuWrapper, LinkItem, IconLabel } from './styled';

function BottomMenuOptions({ options }) {
  const { track } = useAnalytics();

  const $menu = useRef();
  const [visible, toggle] = useToggle();

  useClickOutside([$menu], visible && toggle);
  useEscape(visible && toggle);

  const handleClick = (label, callback) => {
    track('Builder interaction', {
      name: `Clicked ${label} option`,
      component: 'BottomMenuManager',
    });

    callback?.();
  };

  const handleItem = (icon, label, disabled) => (
    <IconLabel disabled={disabled}>
      <Icon icon={icon} />
      {label}
    </IconLabel>
  );

  return (
    <BottomMenuWrapper
      onClick={toggle}
      ref={$menu}
      aria-label={`${visible ? 'Close' : 'Open'} Menu`}
      visible={visible}
    >
      <BrandMark size={20} />
      <Icon icon={faEllipsisVertical} />

      <MenuWrapper>
        <Fade from="bottom" visible={visible}>
          <Menu>
            <List role="list">
              {options.map(({ headerLabel, items }) => (
                <Fragment key={headerLabel}>
                  <MenuHeading>{headerLabel}</MenuHeading>
                  <List aria-label={headerLabel} role="list">
                    {items.map(({ icon, label, href, callback, disabled }) => (
                      <ListItem
                        aria-label={label}
                        key={icon}
                        onClick={
                          !disabled
                            ? () => handleClick(label, callback)
                            : event => event.stopPropagation()
                        }
                        disabled={disabled}
                      >
                        {href ? (
                          <LinkItem
                            href={href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {handleItem(icon, label)}
                          </LinkItem>
                        ) : (
                          handleItem(icon, label, disabled)
                        )}
                      </ListItem>
                    ))}
                  </List>
                </Fragment>
              ))}
            </List>
          </Menu>
        </Fade>
      </MenuWrapper>
    </BottomMenuWrapper>
  );
}

BottomMenuOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string,
      href: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    })
  ),
};

export default BottomMenuOptions;
