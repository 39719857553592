import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faLeftRight } from '@fortawesome/free-solid-svg-icons/faLeftRight';
import { faUpDown } from '@fortawesome/free-solid-svg-icons/faUpDown';
import { Accordion, Icon } from '@appcues/sonar';
import {
  FieldSet,
  Label,
  LightButton,
  Select,
  RadioButton,
  RadioButtonGroup,
} from 'ext/components/ui';
import { selectUserPreferences } from 'entities/user-preferences';
import { selectPreviewType, updatePreview } from 'entities/user-interface';
import { BlockContentShape } from 'entities/block';
import { COLOR_MODES } from 'lib/user-preferences';
import { SELECTED_STATE } from 'lib/user-interface';
import {
  RATING_EMOJI,
  RATING_NUMBER,
  RATING_STAR,
  BLOCK_MODES,
} from 'lib/block';
import { isColorLight } from 'utils/color-handler';
import { mergeProperties } from 'utils/object-handler';
import ColorInput from 'components/ColorInput';
import {
  BLACK_COLOR,
  WHITE_COLOR,
  DEFAULT_COLORS,
  Controls,
  GroupedFieldSet,
  GroupedField,
  HelpLabel,
  useStyleSettings,
  useAccordionClick,
} from 'components/SideBarSettings/Shared';
import RATING_OPTIONS from './RatingOptions';
import RatingFont from './RatingFont';
import { generateDiplayFormat, generateOptions } from './generators';

const [HORIZONTAL, VERTICAL, NPS] = BLOCK_MODES[RATING_NUMBER];
const BASE_COLOR_PROPERTY = {
  [RATING_EMOJI]: 'backgroundColor',
  [RATING_NUMBER]: 'backgroundColor',
  [RATING_STAR]: 'foregroundColor',
};

const MAX_OPTIONS_PER_RATING = {
  [RATING_EMOJI]: {
    [HORIZONTAL]: 5,
    [VERTICAL]: 5,
  },
  [RATING_NUMBER]: {
    [HORIZONTAL]: 10,
    [VERTICAL]: 10,
    [NPS]: 10,
  },
  [RATING_STAR]: {
    [HORIZONTAL]: 5,
    [VERTICAL]: 5,
  },
};

const RadioLabel = styled.span`
  margin-left: 8px;
`;

export function RatingStyle({
  id: blockId,
  ratingType,
  displayFormat,
  options = [],
  onChange,
  selectedPreviewType,
  colorMode,
  onPreviewUpdate,
}) {
  const trackAccordion = useAccordionClick();
  const selectedStateColor = selectedPreviewType(blockId, SELECTED_STATE);

  const [optionDefault] = options;

  const isNumber = ratingType === RATING_NUMBER;
  const minValueFromOption = isNumber ? Number(optionDefault.value) : 0;
  const maxValueFromOption = isNumber
    ? options.slice(-1)[0].value
    : MAX_OPTIONS_PER_RATING[ratingType][displayFormat];

  const {
    content: { style: selectorStyle } = {},
    selectedContent: { style: selectedStyle } = {},
  } = optionDefault ?? {};

  const handleMaxOptions = ({ type, orientation }) => {
    const maxOrientationOptions = MAX_OPTIONS_PER_RATING[type][orientation];

    if (type === RATING_NUMBER) {
      return options.length > maxOrientationOptions
        ? minValueFromOption + maxOrientationOptions
        : maxValueFromOption;
    }

    return maxOrientationOptions;
  };

  const handleRatingNumberOptions = orientation => {
    const updatedOptions = generateOptions({
      startFrom: Number(optionDefault.value),
      maxValue: handleMaxOptions({ type: RATING_NUMBER, orientation }),
      option: optionDefault,
      type: RATING_NUMBER,
      displayFormat: orientation,
      mergeStyles: false,
    });
    return { options: updatedOptions };
  };

  const handleOrientationChange = orientation =>
    onChange({
      displayFormat: generateDiplayFormat(options, orientation),
      ...(ratingType === RATING_NUMBER &&
        handleRatingNumberOptions(orientation)),
    });

  const handleTypeChange = ({ value }) => {
    if (value === ratingType) return;

    const orientation = value === RATING_STAR ? HORIZONTAL : displayFormat;

    const updatedOptions = generateOptions({
      maxValue: handleMaxOptions({ type: value, orientation }),
      option: optionDefault,
      type: value,
      displayFormat: orientation,
    });

    onChange({
      ratingType: value,
      options: updatedOptions,
      leadingFill: value === RATING_STAR,
      displayFormat: orientation,
    });
  };

  const handleOptionsStyle = useCallback(
    ({ key, style }) =>
      options.map(option => mergeProperties(option, [key], { style })),
    [options]
  );

  const handleChange = useCallback(
    key => patch => {
      const handleNumberStyle = () => {
        const { foregroundColor, backgroundColor } = patch ?? {};
        if (foregroundColor && key === 'content') {
          return { borderColor: foregroundColor };
        }

        if (backgroundColor && key === 'selectedContent') {
          const updatedForegroundColor = {
            ...foregroundColor,
            [colorMode]: isColorLight(backgroundColor[colorMode])
              ? BLACK_COLOR
              : WHITE_COLOR,
          };

          return {
            foregroundColor: updatedForegroundColor,
            borderColor: backgroundColor,
          };
        }

        return null;
      };

      const style = {
        ...patch,
        ...(ratingType === RATING_NUMBER && handleNumberStyle()),
      };

      const updatedOptions = handleOptionsStyle({ key, style });
      onChange({ options: updatedOptions });
    },
    [ratingType, colorMode, handleOptionsStyle, onChange]
  );

  const [valueForSelector, handleChangeForSelector] = useStyleSettings({
    onChange: handleChange('content'),
    style: selectorStyle,
    colorMode,
  });

  const [valueForSelectedContent, handleChangeForSelected] = useStyleSettings({
    onChange: handleChange('selectedContent'),
    style: selectedStyle,
    colorMode,
  });

  return (
    <Accordion.Item value="style">
      <Accordion.Header>
        <Accordion.Trigger onClick={() => trackAccordion('Select', 'Style')}>
          Style
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Label htmlFor="rating-type">Rating type</Label>
            <Select
              id="rating-type"
              aria-label="select-rating-type"
              options={RATING_OPTIONS}
              placeholder="Rating type"
              value={RATING_OPTIONS.filter(({ value }) => ratingType === value)}
              onChange={handleTypeChange}
              portal
            />
          </FieldSet>

          {ratingType !== RATING_EMOJI && (
            <FieldSet>
              <Label htmlFor="selector-color">Selector color</Label>
              <ColorInput
                id="selector-color"
                color={valueForSelector.foregroundColor}
                placeholder={DEFAULT_COLORS.unselectedColor}
                onChange={e =>
                  handleChangeForSelector.foregroundColor(e, 'unselectedColor')
                }
              />
            </FieldSet>
          )}

          <GroupedFieldSet>
            <GroupedField>
              <Label htmlFor="selected-color">
                Selected state color
                <HelpLabel>
                  Option to set the color for the <br />
                  selected state of the rating
                </HelpLabel>
              </Label>
              <ColorInput
                id="selected-color"
                color={valueForSelectedContent[BASE_COLOR_PROPERTY[ratingType]]}
                onChange={e =>
                  handleChangeForSelected[BASE_COLOR_PROPERTY[ratingType]](
                    e,
                    'selectedColor'
                  )
                }
              />
            </GroupedField>

            <GroupedField>
              <LightButton
                aria-label="Preview selector color"
                aria-pressed={!!selectedStateColor.show}
                kind="tertiary"
                onClick={() =>
                  onPreviewUpdate({
                    blockId,
                    previewType: selectedStateColor.type ?? SELECTED_STATE,
                    show: !(selectedStateColor.show ?? false),
                  })
                }
              >
                <Icon icon={faEye} size="small" />
              </LightButton>
            </GroupedField>
          </GroupedFieldSet>

          {ratingType === RATING_NUMBER && (
            <RatingFont
              blockId={blockId}
              options={options}
              onChange={onChange}
            />
          )}

          {ratingType !== RATING_STAR && (
            <FieldSet>
              <Label>Orientation</Label>
              <RadioButtonGroup>
                <RadioButton
                  onClick={() => handleOrientationChange(HORIZONTAL)}
                  selected={
                    displayFormat === HORIZONTAL || displayFormat === NPS
                  }
                >
                  <Icon icon={faLeftRight} size="small" />
                  <RadioLabel>Horizontal</RadioLabel>
                </RadioButton>
                <RadioButton
                  onClick={() => handleOrientationChange(VERTICAL)}
                  selected={displayFormat === VERTICAL}
                >
                  <Icon icon={faUpDown} size="small" />
                  <RadioLabel>Vertical</RadioLabel>
                </RadioButton>
              </RadioButtonGroup>
            </FieldSet>
          )}
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

RatingStyle.propTypes = {
  id: PropTypes.string,
  ratingType: PropTypes.oneOf([RATING_STAR, RATING_EMOJI, RATING_NUMBER]),
  displayFormat: PropTypes.oneOf([HORIZONTAL, VERTICAL, NPS]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      content: BlockContentShape,
      selectedContent: BlockContentShape,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  selectedPreviewType: PropTypes.func,
  colorMode: PropTypes.oneOf(COLOR_MODES),
  onPreviewUpdate: PropTypes.func,
};

const mapStateToProps = state => ({
  selectedPreviewType: (blockId, previewType) =>
    selectPreviewType(state, { blockId, previewType }),
  colorMode: selectUserPreferences(state).colorMode,
});

const mapDispatchToProps = {
  onPreviewUpdate: updatePreview,
};

export default connect(mapStateToProps, mapDispatchToProps)(RatingStyle);
