import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  targetRectangleTemplate,
  StyleShape,
  TargetAreaShape,
} from 'entities/step-children';
import { SkippableShape } from 'entities/step-groups';
import { ScreenInsetsShape } from 'entities/screens';
import { PREVIEW_TOOLTIP_ID, IMAGE_SERVICE_SELECTOR } from 'lib/trait';
import { COLOR_MODES } from 'lib/user-preferences';
import { useImageLoad } from 'hooks/use-image-load';
import { CloseX, transformStyles } from 'components/Editor/Primitives';
import {
  calculateFramePosition,
  drawTooltip,
  calculateContentPosition,
} from './handlers';

const TooltipWrapper = styled.div`
  position: absolute;
  transition: top 0.5s ease-in-out, left 0.5s ease-in-out;
`;

const TooltipContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const PreviewTooltip = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  overflow-y: overlay;
  border-width: 0px;
  width: 100%;
  z-index: 0;
  // This allows fixed elements to be relative to the modal and not the viewport
  transform: translate(0);

  &::-webkit-scrollbar {
    width: 0px;
  }

  ${({ customStyle }) => customStyle}
`;

const Tooltip = ({
  id,
  colorMode,
  deviceSize,
  insets,
  isAnchored,
  skippable,
  pointer,
  style,
  targetArea,
  backgroundContent,
  children,
  handleClick,
}) => {
  const $tooltipNode = useRef(null);
  const [$contentNode, isLoaded] = useImageLoad(children);
  const [svgShape, setSvgShape] = useState(null);

  const { config: targetAreaConfig } = targetArea ?? {};
  let targetConfig = targetAreaConfig;

  // Check if the anchored tooltip has a selector, if not we should display the tooltip in
  // the center of the screen using the target rectangle template positions
  if (isAnchored) {
    targetConfig = targetAreaConfig?.selector
      ? targetAreaConfig
      : targetRectangleTemplate.config;
  }

  const tooltipConfig = {
    pointerBase: pointer?.width,
    pointerCornerRadius: pointer?.cornerRadius,
    pointerLength: pointer?.height,
    hidePointer: pointer?.hidden,
    style,
    backgroundContent,
  };

  useEffect(() => {
    const calculateOffsetHeight = () => {
      if (
        isLoaded &&
        $contentNode.current &&
        $tooltipNode.current &&
        deviceSize
      ) {
        const contentNode = $contentNode.current;
        const tooltipNode = $tooltipNode.current;
        const { actualPointerEdge, offsetFromCenter, tooltipFrame } =
          calculateFramePosition({
            contentNode,
            tooltipNode,
            targetConfig,
            tooltipConfig,
            device: {
              width: deviceSize.width,
              height: deviceSize.height,
              insets,
            },
            isAnchored,
          });

        const corners = drawTooltip({
          tooltipFrame,
          tooltipConfig,
          actualPointerEdge,
          offsetFromCenter,
          colorMode,
        });

        calculateContentPosition({
          contentNode,
          tooltipNode,
          tooltipFrame,
          tooltipConfig,
          actualPointerEdge,
          ...corners,
        });

        setSvgShape(corners.svg);
      }
    };

    calculateOffsetHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, children]);

  const {
    alignSelf,
    boxShadow,
    borderColor,
    borderWidth,
    backgroundColor,
    ...previewTooltipStyles
  } = transformStyles(style, colorMode);

  return (
    <TooltipWrapper ref={$tooltipNode} id={IMAGE_SERVICE_SELECTOR}>
      <TooltipContainer>
        {svgShape}
        <PreviewTooltip
          id={id}
          ref={$contentNode}
          customStyle={previewTooltipStyles}
          onClick={handleClick}
        >
          <CloseX skippable={skippable} colorMode={colorMode} />
          {children}
        </PreviewTooltip>
      </TooltipContainer>
    </TooltipWrapper>
  );
};

Tooltip.propTypes = {
  id: PropTypes.oneOf([PREVIEW_TOOLTIP_ID]),
  colorMode: PropTypes.oneOf(COLOR_MODES),
  skippable: SkippableShape,
  style: StyleShape,
  deviceSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  insets: ScreenInsetsShape,
  isAnchored: PropTypes.bool,
  targetArea: TargetAreaShape,
  pointer: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    cornerRadius: PropTypes.number,
    hidden: PropTypes.bool,
  }),
  backgroundContent: PropTypes.shape({
    type: PropTypes.string,
    style: StyleShape,
  }),
  children: PropTypes.node,
  handleClick: PropTypes.func,
};

export default Tooltip;
