import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { Icon } from '@appcues/sonar';
import Tether from 'ext/components/Tether';
import { Button } from 'ext/components/ui';
import useClickOutside from 'ext/lib/hooks/use-click-outside';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import { isEmbedExperience } from 'lib/experiences';
import { selectExperience } from 'entities/experiences';
import { updateAddStepOfType } from 'entities/user-interface';
import { LAYOUT_TRAITS, TRAIT_LABELS } from 'lib/trait';
import { PRESENTATIONS, PRESENTATIONS_TYPES } from 'lib/presentation';
import { PLATFORMS } from 'lib/platform';
import AddMenu from './AddMenu';
import { AddWrapper } from './styled';

export function AddManager({
  selectedTrait,
  selectedPresentation,
  isMenuOpen,
  toggleIsMenuOpen,
  experienceType,
  platform,
  onAdd,
}) {
  const $button = useRef();
  const $menu = useRef();

  useClickOutside([$button, $menu], isMenuOpen && toggleIsMenuOpen);

  const { track } = useAnalytics();

  const onTrack = ({ layoutTrait, presentation, stepType }) => {
    const traitType = TRAIT_LABELS[layoutTrait];
    const presentationType = PRESENTATIONS[layoutTrait][presentation];

    track('Mobile Builder interaction', {
      name: `Added ${presentationType} to ${traitType} ${stepType} Step`,
      component: 'AddManager',
    });
  };

  const handleAddGroup = ({ layoutTrait, presentation }) => {
    onTrack({ layoutTrait, presentation, stepType: 'group' });
    onAdd({ layoutTrait, presentation, stepType: 'group' });
  };

  const handleAddStep = () => {
    onTrack({
      layoutTrait: selectedTrait,
      presentation: selectedPresentation,
      stepType: 'child',
    });
    onAdd({
      layoutTrait: selectedTrait,
      presentation: selectedPresentation,
      stepType: 'child',
    });
  };

  return (
    <AddWrapper>
      <Tether
        attachment={
          <AddMenu
            ref={$menu}
            platform={platform}
            selectedPresentation={selectedPresentation}
            selectedTrait={selectedTrait}
            onClick={toggleIsMenuOpen}
            handleAddGroup={handleAddGroup}
            handleAddStep={handleAddStep}
          />
        }
        visible={isMenuOpen}
        placement="top"
        offset={{ y: 15 }}
      >
        <Button
          ref={$button}
          kind="secondary"
          onClick={
            isEmbedExperience(experienceType) ? handleAddStep : toggleIsMenuOpen
          }
        >
          <Icon icon={faPlus} />
          Add
        </Button>
      </Tether>
    </AddWrapper>
  );
}

AddManager.propTypes = {
  experienceType: PropTypes.string,
  platform: PropTypes.oneOf(PLATFORMS),
  selectedTrait: PropTypes.oneOf(LAYOUT_TRAITS),
  onAdd: PropTypes.func,
  selectedPresentation: PropTypes.oneOf(PRESENTATIONS_TYPES),
  isMenuOpen: PropTypes.bool,
  toggleIsMenuOpen: PropTypes.func,
};

const mapStateToProps = state => {
  const { type, platform } = selectExperience(state) ?? {};
  return {
    experienceType: type,
    platform,
  };
};

const mapDispatchToProps = {
  onAdd: updateAddStepOfType,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddManager);
