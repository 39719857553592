import { useCallback, useEffect, useRef } from 'react';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { Modal, Icon } from '@appcues/sonar';
import { useImagery } from 'context';
import type { ImageType } from 'types';
import { Card } from './Card';
import { EmptyState } from './EmptyState';
import { Grid } from './Grid';
import { GalleryType } from './types';
import { validateImage } from './image-validator';

export const Gallery = ({
  accept = 'image/*',
  maxSize = 1,
  maxDimension,
  container,
  onSelect,
}: GalleryType) => {
  const sentinelRef = useRef<HTMLDivElement>(null);

  const {
    images,
    isLoading,
    isGalleryOpen,
    setIsGalleryOpen,
    pageInfo,
    fetchImages,
  } = useImagery();

  const { number, size, total } = pageInfo;
  const hasItems = total > 0;

  useEffect(() => {
    if (isGalleryOpen) {
      fetchImages(1);
    }
  }, [isGalleryOpen, fetchImages]);

  const fetchNextPage = useCallback(() => {
    if (number * size < total) {
      const nextPage = number + 1;
      fetchImages(nextPage);
    }
  }, [number, size, total, fetchImages]);

  useEffect(() => {
    const currentSentinel = sentinelRef.current;
    if (!currentSentinel) return;

    const observerInstance = new IntersectionObserver(
      entries => {
        const [{ isIntersecting }] = entries;
        if (isIntersecting) {
          fetchNextPage();
        }
      },
      { threshold: 0.1 }
    );

    observerInstance.observe(currentSentinel);

    // eslint-disable-next-line consistent-return
    return () => {
      if (currentSentinel) {
        observerInstance.unobserve(currentSentinel);
      }
      observerInstance.disconnect();
    };
  }, [fetchNextPage]);

  const handleClick = (image: ImageType) => {
    onSelect?.(image);
    setIsGalleryOpen(false);
  };

  return (
    <Modal.Root
      open={isGalleryOpen}
      onOpenChange={() => setIsGalleryOpen(false)}
      container={container}
      size="large"
    >
      <Modal.Title>Image Gallery</Modal.Title>
      <Grid hasItems={hasItems}>
        {hasItems ? (
          <>
            {images.map(image => {
              const { invalidMessage } = validateImage({
                image,
                accept,
                maxSize,
                maxDimension,
              });

              return (
                <Card
                  key={image.id}
                  image={image}
                  onClick={handleClick}
                  invalidMessage={invalidMessage}
                />
              );
            })}
            <div
              ref={sentinelRef}
              className="col-span-full h-4"
              aria-hidden="true"
            />
          </>
        ) : (
          <EmptyState isLoading={isLoading} />
        )}
      </Grid>
      <Modal.Close aria-label="Close">
        <Icon size="large" icon={faXmark} />
      </Modal.Close>
    </Modal.Root>
  );
};
