import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { EditorShape } from 'entities/step-groups';
import { ContentShape, TargetAreaShape } from 'entities/step-children';
import { focus } from 'entities/block';
import { ScreenInsetsShape } from 'entities/screens';
import {
  MODAL,
  TOOLTIP,
  EMBED,
  PREVIEW_MODAL_ID,
  PREVIEW_TOOLTIP_ID,
  PREVIEW_EMBED_ID,
} from 'lib/trait';
import { getLastRowIndex } from 'lib/block';
import { COLOR_MODES, ORIENTATIONS } from 'lib/user-preferences';
import { BLOCK_TYPE } from 'components/BlockContainer';
import { Modal, Tooltip, Embed } from './Primitives';
import DropZone from './DropZone';
import EmptyContent from './EmptyContent';

const TRAIT_SELECTORS = {
  [MODAL]: PREVIEW_MODAL_ID,
  [EMBED]: PREVIEW_EMBED_ID,
  [TOOLTIP]: PREVIEW_TOOLTIP_ID,
};

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ hasStickyBottom }) => hasStickyBottom && `height: 100%;`}
`;

const TraitNode = ({
  content,
  editor,
  targetArea,
  isAnchored,
  colorMode,
  deviceSize,
  insets,
  orientation,
  device,
  children,
  onDrop,
  onClick,
}) => {
  const {
    presentation,
    skippable,
    pagingDots,
    pointer,
    backgroundContent,
    style,
    type,
  } = editor ?? {};

  const id = TRAIT_SELECTORS[type];

  // Unfocus element, block or trait selected, when layout trait preview is clicked
  const handleClick = ({ target }) => {
    if (target?.id === id) {
      onClick(null);
    }
  };

  const commonProps = {
    id,
    colorMode,
    device,
    skippable,
    style,
    backgroundContent,
    handleClick,
  };

  const handleContent = () => {
    const dropZoneId = uuid();
    const { sticky } = content?.items?.[getLastRowIndex(content)] ?? {};
    const isEmpty = !children.props?.items?.length;
    const contentItems = isEmpty ? <EmptyContent /> : children;

    return (
      <Content hasStickyBottom={!!sticky}>
        <DropZone id={dropZoneId} onDrop={onDrop} type={BLOCK_TYPE} newRow />
        {contentItems}
      </Content>
    );
  };

  /* eslint-disable @appcues/jsx-props-no-spreading */
  if (type === MODAL) {
    return (
      <Modal
        {...commonProps}
        deviceSize={deviceSize}
        presentation={presentation}
        orientation={orientation}
        pagingDots={pagingDots}
      >
        {handleContent()}
      </Modal>
    );
  }

  if (type === TOOLTIP) {
    return (
      <Tooltip
        {...commonProps}
        isAnchored={isAnchored}
        deviceSize={deviceSize}
        targetArea={targetArea}
        pointer={pointer}
        insets={insets}
      >
        {handleContent()}
      </Tooltip>
    );
  }

  if (type === EMBED) {
    return (
      <Embed
        {...commonProps}
        presentation={presentation}
        orientation={orientation}
        pagingDots={pagingDots}
      >
        {handleContent()}
      </Embed>
    );
  }
  /* eslint-enable @appcues/jsx-props-no-spreading */

  return null;
};

TraitNode.propTypes = {
  content: ContentShape,
  editor: EditorShape,
  targetArea: TargetAreaShape,
  colorMode: PropTypes.oneOf(COLOR_MODES),
  orientation: PropTypes.oneOf(ORIENTATIONS),
  deviceSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  isAnchored: PropTypes.bool,
  insets: ScreenInsetsShape,
  device: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    isDefault: PropTypes.bool,
    isTablet: PropTypes.bool,
  }),
  children: PropTypes.node,
  onDrop: PropTypes.func,
  onClick: PropTypes.func,
};

const mapDispatchToProps = {
  onClick: focus,
};

export default connect(null, mapDispatchToProps)(TraitNode);
