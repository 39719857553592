import { createContext, useContext } from 'react';
import type { ContextType } from 'types';

const Context = createContext<ContextType | undefined>(undefined);

export const useImagery = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useImagery must be used within an ImageryProvider');
  }

  return context;
};

export default Context;
