import React from 'react';
import PropTypes from 'prop-types';
import { ContentShape } from 'entities/step-children';
import { COLOR_MODES } from 'lib/user-preferences';
import {
  BLOCK,
  BOX,
  BUTTON,
  IMAGE,
  OPTION_SELECT,
  ROW,
  SPACER,
  STACK,
  TEXT,
  TEXT_INPUT,
  VIDEO,
  CUSTOM_COMPONENT,
} from 'lib/block';
import { TextContainer, VideoContainer } from './Containers';
import { Block, Box, Row, Spacer, Stack } from './Primitives/Layout';
import { Button, CustomComponent, Image } from './Primitives/Content';
import { TextInput, SelectType } from './Primitives/Survey';

/* eslint-disable @appcues/jsx-props-no-spreading */
export default function ContentNode({
  content,
  items,
  onDrop,
  colorMode,
  ...props
}) {
  switch (props.type) {
    /* Structure Blocks */

    case BLOCK:
      return (
        <Block {...props} onDrop={onDrop}>
          <ContentNode {...content} colorMode={colorMode} onDrop={onDrop} />
        </Block>
      );

    case STACK: {
      const stack = (
        <Stack {...props} onDrop={onDrop} colorMode={colorMode}>
          {items?.map(item => (
            <ContentNode
              {...item}
              key={item.id}
              colorMode={colorMode}
              onDrop={onDrop}
            />
          ))}
        </Stack>
      );

      return props.role === ROW ? (
        <Row {...props} onDrop={onDrop}>
          {stack}
        </Row>
      ) : (
        stack
      );
    }

    case BOX:
      return (
        <Box {...props}>
          {items?.map(item => (
            <ContentNode
              {...item}
              key={item.id}
              colorMode={colorMode}
              onDrop={onDrop}
            />
          ))}
        </Box>
      );

    /* Content Blocks */

    case IMAGE:
      return <Image {...props} colorMode={colorMode} />;

    case TEXT:
      return <TextContainer {...props} colorMode={colorMode} />;

    case SPACER:
      return <Spacer {...props} colorMode={colorMode} />;

    case BUTTON:
      return (
        <Button {...props} colorMode={colorMode}>
          <ContentNode {...content} colorMode={colorMode} />
        </Button>
      );

    case VIDEO:
      return <VideoContainer {...props} colorMode={colorMode} />;

    case CUSTOM_COMPONENT:
      return <CustomComponent {...props} colorMode={colorMode} />;

    /* Survey Blocks */

    case TEXT_INPUT:
      return <TextInput {...props} colorMode={colorMode} />;

    case OPTION_SELECT: {
      return <SelectType {...props} colorMode={colorMode} />;
    }

    default:
      return null;
  }
}
/* eslint-enable @appcues/jsx-props-no-spreading */

ContentNode.propTypes = {
  content: ContentShape,
  items: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  type: PropTypes.string,
  role: PropTypes.string,
  selectMode: PropTypes.string,
  onDrop: PropTypes.func,
  colorMode: PropTypes.oneOf(COLOR_MODES),
};
