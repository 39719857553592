import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Icon } from '@appcues/sonar';
import { faExpand } from '@fortawesome/pro-solid-svg-icons/faExpand';
import { faCompress } from '@fortawesome/pro-solid-svg-icons/faCompress';
import {
  FieldSet,
  Label,
  RadioButton,
  RadioButtonGroup,
} from 'ext/components/ui';
import { COLOR_MODES } from 'lib/user-preferences';
import { Shape as BlockContentShape } from 'entities/block';
import {
  Controls,
  RadioButtonLabel,
  useStyleSettings,
  useAccordionClick,
} from 'components/SideBarSettings/Shared';
import ColorInput from 'components/ColorInput';

// content modes
const CONTENT_MODE = {
  fit: 'fit',
  fill: 'fill',
};

export default function Style({ content, onChange, colorMode = 'light' }) {
  const trackAccordion = useAccordionClick();

  const { id, style } = content ?? {};
  const { backgroundImage } = style ?? {};

  const handleBackgroundImageChange = useCallback(
    contentMode => {
      onChange({
        blockId: id,
        contentChunk: {
          style: {
            ...style,
            backgroundImage: {
              ...style.backgroundImage,
              ...(contentMode && { contentMode }),
            },
          },
        },
        useOriginalId: true,
      });
    },
    [id, style, onChange]
  );

  const handleChange = useCallback(
    patch => {
      onChange({
        blockId: id,
        contentChunk: { style: patch },
        useOriginalId: true,
      });
    },
    [id, onChange]
  );

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange,
    style,
    colorMode,
  });

  return (
    <Accordion.Item value="style">
      <Accordion.Header>
        <Accordion.Trigger onClick={() => trackAccordion('Hero', 'Style')}>
          Style
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          {backgroundImage && (
            <FieldSet>
              <RadioButtonGroup>
                <RadioButton
                  onClick={() => handleBackgroundImageChange(CONTENT_MODE.fill)}
                  selected={backgroundImage?.contentMode === CONTENT_MODE.fill}
                >
                  <RadioButtonLabel>
                    <Icon icon={faExpand} />
                    Fill
                  </RadioButtonLabel>
                </RadioButton>
                <RadioButton
                  onClick={() => handleBackgroundImageChange(CONTENT_MODE.fit)}
                  selected={backgroundImage?.contentMode === CONTENT_MODE.fit}
                >
                  <RadioButtonLabel>
                    <Icon icon={faCompress} />
                    Fit
                  </RadioButtonLabel>
                </RadioButton>
              </RadioButtonGroup>
            </FieldSet>
          )}

          <FieldSet>
            <Label htmlFor="button-color-input">Background Color</Label>
            <ColorInput
              id="button-color-input"
              color={valueFor.backgroundColor}
              onChange={handleChangeFor.backgroundColor}
            />
          </FieldSet>
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

Style.propTypes = {
  content: BlockContentShape,
  onChange: PropTypes.func,
  colorMode: PropTypes.oneOf(COLOR_MODES),
};
