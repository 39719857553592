type GenericState = {
  status: 'idle' | 'focus' | 'uploading';
};

type WarningState = {
  status: 'warning';
  warningMessage: string;
};

type ShowingImageState = {
  status: 'showing_image';
  image: string;
};

type UploadErrorState = {
  status: 'error';
  errorMessage: string;
};

type State = GenericState | WarningState | ShowingImageState | UploadErrorState;

type Action =
  | { type: 'DRAG_OVER' }
  | { type: 'DRAG_LEAVE' }
  | { type: 'START_UPLOAD' }
  | { type: 'UPLOAD_FINISHED'; image: string }
  | { type: 'INVALIDATE_IMAGE'; warningMessage: string }
  | { type: 'UPLOAD_ERROR'; errorMessage: string }
  | { type: 'CLEAN_FILE' }
  | { type: 'RESET_STATUS' }
  | { type: 'IMAGE_SELECTED'; image: string };

export const initialState: State = {
  status: 'idle',
};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'DRAG_OVER':
      return { status: 'focus' };
    case 'DRAG_LEAVE':
      return { status: 'idle' };
    case 'START_UPLOAD':
      return { status: 'uploading' };
    case 'UPLOAD_FINISHED':
      return { status: 'showing_image', image: action.image };
    case 'INVALIDATE_IMAGE':
      return { status: 'warning', warningMessage: action.warningMessage };
    case 'UPLOAD_ERROR':
      return { status: 'error', errorMessage: action.errorMessage };
    case 'CLEAN_FILE':
      return { status: 'idle' };
    case 'RESET_STATUS':
      return { status: 'idle' };
    case 'IMAGE_SELECTED':
      return { status: 'showing_image', image: action.image };
    default:
      return state;
  }
}
