import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BlockContentShape } from 'entities/block';
import { RATING_EMOJI, RATING_NUMBER, RATING_STAR } from 'lib/block';
import { COLOR_MODES } from 'lib/user-preferences';
import { Emoji, transformStyles } from 'components/Editor/Primitives';

const RatingOptionContainer = styled.div``;

const RatingItem = styled.div`
  display: flex;
`;

const RatingText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RatingOption = ({
  index,
  content,
  selectedContent,
  value,
  previewSelectedColor,
  colorMode,
  ratingType,
  onUpdate,
}) => {
  const { text, style } = content;
  const { text: selectedText, style: selectedStyle } = selectedContent;

  const showSelected = index === 0 && previewSelectedColor;
  const textToShow = showSelected ? selectedText : text;

  const optionStyles = transformStyles(
    showSelected ? selectedStyle : style,
    colorMode
  );

  const handleUpdate = updatedValue => {
    onUpdate(index, updatedValue);
  };

  return (
    <RatingOptionContainer id={value}>
      <RatingItem key={value}>
        <RatingText style={optionStyles}>
          {ratingType === RATING_EMOJI ? (
            <Emoji
              id={value}
              text={textToShow}
              colorMode={colorMode}
              onUpdate={handleUpdate}
            />
          ) : (
            textToShow
          )}
        </RatingText>
      </RatingItem>
    </RatingOptionContainer>
  );
};

RatingOption.propTypes = {
  index: PropTypes.number,
  ratingType: PropTypes.oneOf([RATING_EMOJI, RATING_NUMBER, RATING_STAR]),
  content: BlockContentShape,
  selectedContent: BlockContentShape,
  value: PropTypes.string,
  previewSelectedColor: PropTypes.bool,
  colorMode: PropTypes.oneOf(COLOR_MODES),
  onUpdate: PropTypes.func,
};

export default RatingOption;
