import { COLOR_MODES } from 'lib/user-preferences';
import { getFontDetails } from 'components/SideBarSettings/Shared/Fonts/fonts-handler';

const [LIGHT] = COLOR_MODES;

/**
 * Convert numeric values to pixels
 */
const asPx = val => `${val}px`;

/**
 * Grab color for colorMode
 *
 * @param {Object} val - an object containing light (required) and dark (optional) colorMode colors
 */
const asColor = (val, colorMode) => val[colorMode] || val[LIGHT];

/**
 * Convert mobile property to flex-box value
 */
const asFlex = val => {
  switch (val) {
    case 'leading':
    case 'top':
      return 'flex-start';
    case 'bottom':
    case 'trailing':
      return 'flex-end';
    case 'center':
    default:
      return val;
  }
};

/**
 * Convert mobile font family to related font family on web
 */

const asFontFamily = val => getFontDetails(val);

/**
 * Convert gradient object to string expression
 */
const asGradient = ({ colors /* startPoint, endPoint */ }, colorMode) => {
  const [begin, end] = colors;
  return `linear-gradient(90deg, ${asColor(begin, colorMode)} 0%, ${asColor(
    end,
    colorMode
  )} 100%)`;
};

/**
 * Convert shadow object to string expression
 */
const asShadow = ({ x, y, radius, color }, colorMode) =>
  `${asPx(x)} ${asPx(y)} ${asPx(radius)} ${asColor(color, colorMode)}`;

/**
 * Convert mobile property to text-alignment
 */
const asAlign = val => {
  switch (val) {
    case 'trailing':
      return 'end';
    case 'leading':
      return 'start';
    default:
      return val;
  }
};

/**
 * Convert mobile property to background-position
 */
const asBackgroundPosition = val => {
  switch (val) {
    case 'trailing':
      return 'right';
    case 'leading':
      return 'left';
    default:
      return val;
  }
};

/**
 * Convert backgroundImage object to string expression
 */
const asBackgroundImage = ({
  imageUrl,
  contentMode,
  horizontalAlignment,
  verticalAlignment,
}) => ({
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: contentMode === 'fill' ? 'cover' : 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: `${asBackgroundPosition(
    horizontalAlignment
  )} ${verticalAlignment}`,
});

/*
 * Map SwiftUI styles to CSS properties
 */
const mapPropsToCSS = (prop, val, colorMode) => {
  switch (prop) {
    case 'backgroundColor':
      return ['backgroundColor', asColor(val, colorMode)];
    case 'backgroundImage':
      return ['backgroundImage', asBackgroundImage(val)];
    case 'backgroundGradient':
      return ['background', asGradient(val, colorMode)];
    case 'borderColor':
      return ['borderColor', asColor(val, colorMode)];
    case 'outlineColor':
      return ['outlineColor', asColor(val, colorMode)];
    case 'cornerRadius':
      return ['borderRadius', asPx(val)];
    case 'fontName':
      return ['fontFamily', asFontFamily(val)];
    case 'foregroundColor':
      return ['color', asColor(val, colorMode)];
    case 'horizontalAlignment':
      return ['justifyContent', asFlex(val)];
    case 'lineSpacing':
      return ['lineHeight', asPx(val)];
    case 'marginLeading':
      return ['marginInlineStart', asPx(val)];
    case 'marginTrailing':
      return ['marginInlineEnd', asPx(val)];
    case 'paddingLeading':
      return ['paddingInlineStart', asPx(val)];
    case 'paddingTrailing':
      return ['paddingInlineEnd', asPx(val)];
    case 'shadow':
      return ['boxShadow', asShadow(val, colorMode)];
    case 'spacing':
      return ['gap', asPx(val)];
    case 'textAlignment':
      return ['textAlign', asAlign(val)];
    case 'verticalAlignment':
      return ['alignSelf', asFlex(val)];
    case 'width':
      return ['width', val === -1 ? '100%' : asPx(val)];
    default:
      if (typeof val === 'number') return [prop, asPx(val)];
      return [prop, val];
  }
};

export default function transformStyles(styles = {}, colorMode = 'light') {
  return Object.entries(styles).reduce((acc, prop) => {
    const [key, value] = mapPropsToCSS(...prop, colorMode);

    // If the property passed is an object
    // we need to apply all the styles inside
    if (typeof value === 'object') {
      return {
        ...acc,
        ...value,
      };
    }

    // Apply border only if border color and border width exist and are valid values
    if (key === 'borderColor' && styles.borderWidth) {
      acc.borderStyle = 'solid';
    }

    acc[key] = value;
    return acc;
  }, {});
}
