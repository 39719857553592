import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { Icon } from '@appcues/sonar';
import useClickOutside from 'ext/lib/hooks/use-click-outside';
import useEscape from 'ext/lib/hooks/use-escape';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import Tether from 'ext/components/Tether';
import { selectSelected } from 'entities/selected';
import { selectStepGroup } from 'entities/step-groups';
import { selectStepChild, update, ContentShape } from 'entities/step-children';
import { focus } from 'entities/block';
import {
  InsertComponentPositionShape,
  selectInsertComponentPosition,
  updateInsertComponentPosition,
} from 'entities/user-interface';
import { LAYOUT_TRAITS } from 'lib/trait';
import {
  BUTTON,
  CUSTOM_COMPONENT,
  TEXT,
  BLOCK_LABELS,
  SURVEY,
  BLOCK_CATEGORIES,
  hasSurveyBlockType,
} from 'lib/block';
import { addSubmitFormAction, Shape as ActionsShape } from 'lib/actions';
import { getLocaleVariations } from 'lib/localization';
import ContentMenu from 'components/ContentMenu';
import { updateButtonActions } from './update-button-actions';
import { addElement } from './add-element';
import {
  AddButton,
  CustomComponentPlaceholder,
  DividerWrapper,
  Divider,
  DividerBackground,
} from './styled';

const BlockDivider = ({
  rowId,
  position = 'top',
  isFirstRow,
  isLastRow,
  isEmpty,
  layoutTrait,
  stepId,
  content,
  actions,
  insertComponentPosition,
  onUpdate,
  onClick,
  onInsertCustomComponent,
}) => {
  const { track } = useAnalytics();
  const [isContentMenuVisible, setIsContentMenuVisible] = useState(false);
  const $contentMenu = useRef();

  const isInsertingCustomComponent =
    insertComponentPosition.rowId === rowId &&
    insertComponentPosition.position === position;

  const isSideBySide = position === 'right';

  const handleAddElementClick = () => {
    onClick();
    setIsContentMenuVisible(true);
  };

  const handleSelectElementClick = async template => {
    track('Mobile Builder interaction', {
      name: `Added ${BLOCK_LABELS[template]} Block`,
      component: 'BlockDivider',
    });

    if (template === CUSTOM_COMPONENT) {
      onInsertCustomComponent({ rowId, position });
      setIsContentMenuVisible(false);
      return;
    }

    const newBlockId = uuid();
    const newElementId = uuid();

    const handleActions = () => {
      if (BLOCK_CATEGORIES[SURVEY].includes(template)) {
        const updatedActions = addSubmitFormAction(actions);
        return { actions: updatedActions };
      }

      if (template === BUTTON) {
        const updatedActions = updateButtonActions({
          actions,
          buttonId: newElementId,
          addSubmitFormAction: hasSurveyBlockType(content),
        });
        return { actions: updatedActions };
      }

      return {};
    };

    const updated = {
      ...handleActions(),
      ...addElement({
        content,
        template,
        position,
        newBlockId,
        newElementId,
        rowId,
        newRow: !isSideBySide,
        ...(template === TEXT && { layoutTrait }),
        locales: getLocaleVariations(content),
      }),
    };

    await onUpdate(stepId, updated);
    setIsContentMenuVisible(false);
  };

  const handleClickOutside = () => {
    setIsContentMenuVisible(false);
  };

  useClickOutside([$contentMenu], isContentMenuVisible && handleClickOutside);
  useEscape(isContentMenuVisible && handleClickOutside);

  if (isInsertingCustomComponent) {
    return <CustomComponentPlaceholder isSideBySide={isSideBySide} />;
  }

  return (
    <DividerWrapper
      visible={isEmpty || isContentMenuVisible}
      hideDividerLine={isEmpty || position === 'right'}
    >
      <Divider
        position={position}
        data-apc-block="block-divider"
        data-content-menu-open={isContentMenuVisible}
      >
        <Tether
          attachment={
            <ContentMenu
              ref={$contentMenu}
              layoutTrait={layoutTrait}
              onClick={handleSelectElementClick}
            />
          }
          visible={isContentMenuVisible}
          placement="top-right"
          offset={{ x: position !== 'right' ? -100 : 10, y: -170 }}
          wrapped
        >
          <>
            <AddButton
              aria-label="Open content menu"
              onClick={handleAddElementClick}
              position={position}
              isFirstRow={isFirstRow}
              isLastRow={isLastRow}
              data-content-menu-open={isContentMenuVisible}
            >
              <Icon icon={faPlus} size="x-small" />
            </AddButton>
            {position !== 'right' && <DividerBackground position={position} />}
          </>
        </Tether>
      </Divider>
    </DividerWrapper>
  );
};

BlockDivider.propTypes = {
  rowId: PropTypes.string,
  position: PropTypes.oneOf(['top', 'bottom', 'right']),
  isFirstRow: PropTypes.bool,
  isLastRow: PropTypes.bool,
  isEmpty: PropTypes.bool,
  layoutTrait: PropTypes.oneOf(LAYOUT_TRAITS),
  stepId: PropTypes.string,
  content: ContentShape,
  actions: ActionsShape,
  insertComponentPosition: InsertComponentPositionShape,
  onUpdate: PropTypes.func,
  onClick: PropTypes.func,
  onInsertCustomComponent: PropTypes.func,
};

const mapStateToProps = state => {
  const { stepGroup: stepGroupId, stepChild: stepId } =
    selectSelected(state) ?? {};
  const { editor: { type: layoutTrait } = {} } =
    selectStepGroup(state, stepGroupId) ?? {};
  const { content, actions } = selectStepChild(state, stepId) ?? {};
  const insertComponentPosition = selectInsertComponentPosition(state);

  return { layoutTrait, stepId, content, actions, insertComponentPosition };
};

const mapDispatchToProps = {
  onClick: focus,
  onUpdate: update,
  onInsertCustomComponent: updateInsertComponentPosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockDivider);
