import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button, Icon } from '@appcues/sonar';

const CustomFontButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 15px 7px 12px;
`;

const CustomFontButton = ({ onClick }) => (
  <CustomFontButtonWrapper>
    <Button onClick={onClick} variant="secondary">
      <Icon icon={faPlus} />
      Add Custom Font
    </Button>
  </CustomFontButtonWrapper>
);

CustomFontButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CustomFontButton;
