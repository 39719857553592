import styled from 'styled-components';
import { ButtonGroup, Icon, Text } from '@appcues/sonar';
import { MenuHeading } from 'ext/components/ui';
import { easing } from 'ext/lib/style/easing';

export const Body = styled.div`
  ${ButtonGroup} {
    justify-content: flex-end;
    margin-top: var(--spacing-large);
  }
`;

export const AccountName = styled.p`
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-medium);
`;

export const Account = styled.div.attrs({ role: 'button' })`
  align-items: center;
  background: var(--color-neutral-0);
  border: 1px solid var(--color-neutral-300);
  border-radius: 6px;
  box-shadow: none;
  display: flex;
  height: 64px;
  justify-content: space-between;
  max-width: 600px;
  padding: 12px var(--spacing-regular);
  cursor: pointer;
  transition: ${easing('border', 'box-shadow')};
  width: 100%;

  :hover {
    border-color: var(--color-blurple-600);
  }

  & + & {
    margin-top: 16px;
  }

  ${Text} {
    font-size: var(--font-size-small);
  }

  ${({ $selected }) =>
    $selected &&
    `
      border-color: var(--color-blurple-600);
      border-width: 2px;
      box-shadow: var(--level-2);
      transition: none;
  `}

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;

      ${AccountName}, ${Text} {
        color: var(--color-neutral-500);
      }
  `}
`;

export const BottomMenuWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  left: 46px;
  bottom: calc(var(--bottom-bar-height) / 2);
  height: 40px;
  width: 58px;
  background: var(--color-neutral-600);
  background: var(--diel-gray);
  color: var(--color-neutral-0);
  border: none;
  border-radius: 6px;
  padding: 0 13px;
  transform: translate3d(-50%, 50%, 0);
  cursor: pointer;

  ${({ visible }) =>
    visible &&
    `
    background: var(--color-neutral-600);
    outline: 1px solid var(--color-neutral-0);
    `}

  svg path {
    fill: var(--color-neutral-0);
  }
`;

export const MenuWrapper = styled.div`
  position: absolute;
  bottom: 65px;
  left: 0;
  text-align: left;

  ${MenuHeading} {
    margin-left: var(--spacing-small);
  }
`;

export const LinkItem = styled.a`
  display: flex;
  flex: 1;
  text-decoration: none;
  color: var(--color-neutral-0);
`;

export const IconLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-semibold);
  text-align: left;
  cursor: pointer;

  ${Icon} {
    min-width: 30px;
    margin-right: var(--spacing-x-small);
    padding: 0px 6px;
  }

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      color: var(--color-neutral-500);

      && svg path {
        fill: var(--color-neutral-500);
      }
  `}
`;
