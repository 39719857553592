import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import EditButton from './EditButton';

const SelectOptions = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  font-size: 16px;
  line-height: 42px;
  padding-left: 16px;
  overflow-y: hidden;

  &:focus-visible {
    outline: 2px solid var(--color-blue-500);
  }
`;

const Container = styled.div`
  position: relative;
`;

const EditableOptions = ({ text, onBlur }) => {
  const $textarea = useRef();
  const [textAreaValue, setTextAreaValue] = useState(text);

  const resizeTextArea = () => {
    const { current } = $textarea;
    current.style.height = 'auto';
    current.style.height = `${current.scrollHeight}px`;
  };

  useEffect(resizeTextArea, [textAreaValue]);

  useEffect(() => {
    $textarea.current.focus();
  }, []);

  const handleChange = evt => {
    const val = evt.target?.value;
    setTextAreaValue(val);
  };

  const handleBlur = ({ currentTarget: { value } }) => {
    onBlur(value);
  };

  const placeholder = `Place each option on a new line, e.g.
    Option 1
    Option 2
    Option 3`;

  return (
    <Container>
      <SelectOptions
        ref={$textarea}
        placeholder={placeholder}
        defaultValue={textAreaValue}
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={event => event.stopPropagation()}
        onFocus={({ currentTarget }) => {
          const cursorPosition = currentTarget.value.length;
          return currentTarget.setSelectionRange(
            cursorPosition,
            cursorPosition
          );
        }}
      />
      <EditButton icon={faCheck} />
    </Container>
  );
};

EditableOptions.propTypes = {
  text: PropTypes.string,
  onBlur: PropTypes.func,
};

export default EditableOptions;
