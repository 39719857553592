import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import useToggle from 'ext/lib/hooks/use-toggle';
import {
  update,
  selectStepChild,
  ContentShape,
  ColorShape,
  StyleShape,
} from 'entities/step-children';
import { selectSelected } from 'entities/selected';
import { selectActiveLocale } from 'entities/locales';
import { selectPreview } from 'entities/user-interface';
import { focus, BlockContentShape } from 'entities/block';
import {
  TEXT,
  OPTION_SELECT,
  BLOCK_MODES,
  getParentBlock,
  getElement,
  updateElement,
} from 'lib/block';
import { ERROR_COLOR, ERROR_MESSAGE, SELECTED_STATE } from 'lib/user-interface';
import { COLOR_MODES } from 'lib/user-preferences';
import optionsHandler from 'lib/block/options-handler';
import {
  SelectOption,
  ErrorMessage,
  EditableOptions,
  handleErrorStyle,
  interactionStyles,
  transformStyles,
} from 'components/Editor/Primitives';
import { EditButton } from 'components/Editor/Primitives/Survey';
import TextContainer from './TextContainer';

const Container = styled.div``;

const SelectWrapper = styled.div`
  position: relative;

  ${({ isParentSelected, isSelected }) =>
    interactionStyles({
      unreach: !isParentSelected && !isSelected,
      selected: isSelected,
      hovered: !isSelected,
      outline: false,
    })}
`;

const SelectContainer = ({
  id,
  label,
  selectMode,
  selectedColor,
  unselectedColor,
  options,
  required,
  errorLabel,
  style,
  colorMode,
  selectedPreview,
  stepId,
  selectedBlock,
  content,
  localeId,
  onClick,
  onUpdate,
}) => {
  const [defaultOption] = options;
  const styles = transformStyles(style, colorMode);

  const { id: parentId } = getParentBlock(content, id) ?? {};
  const isParentSelected = selectedBlock === parentId;
  const isSelected = selectedBlock === defaultOption.content.id;

  const [editing, toggle] = useToggle(false);

  const {
    [ERROR_COLOR]: previewErrorColor,
    [ERROR_MESSAGE]: previewErrorMessage,
    [SELECTED_STATE]: previewSelectedColor,
  } = selectedPreview(id);

  const { id: labelId, text: labelText, spans, style: baseLabelStyle } = label;

  const { text: errorMessage, style: errorStyle } = errorLabel;
  const { color: errorColor, ...errorStyles } = transformStyles(
    errorStyle,
    colorMode
  );

  const labelStyles = previewErrorColor
    ? handleErrorStyle({ style: baseLabelStyle, color: errorColor })
    : baseLabelStyle;

  const optionsString = options
    .map(({ content: optionContent }) => optionContent.text)
    .join('\n');

  const handleClick = event => {
    onClick(defaultOption.content.id);
    event.stopPropagation();
  };

  const handleClickToEdit = event => {
    toggle();
    event.stopPropagation();
  };

  const handleBlur = textValue => {
    toggle();

    if (textValue === optionsString || !textValue) {
      return;
    }

    const { options: optionsWithVariations } = getElement(content, id);

    const updatedOptions = optionsHandler(
      optionsWithVariations,
      textValue,
      localeId
    );

    const updatedContent = updateElement({
      content,
      blockId: id,
      contentChunk: { options: updatedOptions },
    });

    onUpdate(stepId, updatedContent);
  };

  return (
    <Container id={id} style={styles}>
      <TextContainer
        id={labelId}
        text={labelText}
        spans={spans}
        label={{
          errorColor: labelStyles.color,
          required,
        }}
        style={labelStyles}
        colorMode={colorMode}
      />

      {editing ? (
        <EditableOptions text={optionsString} onBlur={handleBlur} />
      ) : (
        <SelectWrapper
          onClick={handleClick}
          isSelected={isSelected}
          isParentSelected={isParentSelected}
        >
          {options.map(({ content: contentOption }, index) => (
            <SelectOption
              index={index}
              key={`${contentOption.id}-${contentOption.text}}`}
              content={contentOption}
              selectMode={selectMode}
              selectedColor={selectedColor}
              unselectedColor={unselectedColor}
              previewSelectedColor={previewSelectedColor}
              error={{ previewErrorColor, errorColor }}
              colorMode={colorMode}
            />
          ))}
          <EditButton icon={faPenToSquare} onClick={handleClickToEdit} />
        </SelectWrapper>
      )}

      {required && previewErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          color={errorColor}
          transformedStyle={errorStyles}
        />
      )}
    </Container>
  );
};

SelectContainer.propTypes = {
  id: PropTypes.string,
  label: BlockContentShape,
  selectMode: PropTypes.oneOf(BLOCK_MODES[OPTION_SELECT]),
  selectedColor: ColorShape,
  unselectedColor: ColorShape,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      content: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.oneOf([TEXT]),
        text: PropTypes.string,
        style: StyleShape,
      }),
    })
  ),
  required: PropTypes.bool,
  errorLabel: BlockContentShape,
  style: StyleShape,
  colorMode: PropTypes.oneOf(COLOR_MODES),
  selectedPreview: PropTypes.func,
  content: ContentShape,
  localeId: PropTypes.string,
  stepId: PropTypes.string,
  selectedBlock: PropTypes.string,
  onClick: PropTypes.func,
  onUpdate: PropTypes.func,
};

const mapStateToProps = state => {
  const { stepChild: stepId, block: selectedBlock } =
    selectSelected(state) ?? {};
  const { content } = selectStepChild(state, stepId) ?? {};
  const selectedPreview = blockId => selectPreview(state, blockId);
  const { id: localeId } = selectActiveLocale(state) ?? {};

  return { stepId, selectedBlock, content, localeId, selectedPreview };
};

const mapDispatchToProps = {
  onClick: focus,
  onUpdate: update,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectContainer);
