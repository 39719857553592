import {
  validateDimension,
  validateFormat,
  validateSize,
} from 'components/validators';
import { ImageType } from 'types';

type ValidateImageProps = {
  image: ImageType;
  accept: string;
  maxSize: number;
  maxDimension?: number;
};

export const validateImage = ({
  image,
  accept,
  maxSize,
  maxDimension,
}: ValidateImageProps) => {
  const validations = [
    {
      isValid: validateFormat(image, accept),
      message: 'format',
    },
    {
      isValid: validateSize(image, maxSize),
      message: 'size',
    },
    {
      isValid: validateDimension(image, maxDimension),
      message: 'dimension',
    },
  ];

  const invalidMessages = validations
    .filter(validation => !validation.isValid)
    .map(validation => validation.message);
  const isValid = invalidMessages.length === 0;
  const tooltipContent = !isValid
    ? `Invalid ${invalidMessages.join(', ').replace(/, ([^,]*)$/, ' and $1')}`
    : '';

  return { status: isValid, invalidMessage: tooltipContent };
};
