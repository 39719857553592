import React from 'react';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { Button, Icon } from '@appcues/sonar';
import { enabled } from 'ext/lib/devtools';
import useToggle from 'ext/lib/hooks/use-toggle';
import Tether from 'ext/components/Tether';
import { Shape as BlockShape } from 'entities/block';
import { BlockSchemeWrapper, BlockCodeWrapper, BlockCode } from './styled';

const BlockScheme = ({ blockProperties }) => {
  const [isBlockSchemeVisible, setIsBlockSchemeVisible] = useToggle(false);

  return enabled() ? (
    <BlockSchemeWrapper>
      <Tether
        attachment={
          <BlockCodeWrapper>
            <BlockCode>{JSON.stringify(blockProperties, null, 2)}</BlockCode>
          </BlockCodeWrapper>
        }
        visible={isBlockSchemeVisible}
      >
        <Button
          aria-label="Block scheme button"
          onClick={setIsBlockSchemeVisible}
          iconOnly
        >
          <Icon icon={faCode} />
        </Button>
      </Tether>
    </BlockSchemeWrapper>
  ) : null;
};

BlockScheme.propTypes = {
  blockProperties: BlockShape,
};

export default BlockScheme;
