import React from 'react';
import PropTypes from 'prop-types';
import { faClone } from '@fortawesome/free-solid-svg-icons/faClone';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faMapPin } from '@fortawesome/free-solid-svg-icons/faMapPin';
import { Icon } from '@appcues/sonar';
import { Tooltip } from 'ext/components/ui';
import { STICKY_OPTIONS } from 'lib/block';
import { Button } from './styled';

const buttonTypes = {
  clone: {
    ariaLabel: 'Clone element',
    icon: faClone,
  },
  delete: {
    ariaLabel: 'Delete element',
    icon: faTrash,
  },
  sticky: {
    ariaLabel: 'Sticky row',
    icon: faMapPin,
  },
};

const ToolbarButton = ({ type, onClick, row }) => {
  const { id, allowedToStickyPosition, isSticky } = row ?? {};
  const { ariaLabel, icon } = buttonTypes[type] ?? {};

  const handleClick = event => {
    event.stopPropagation();
    onClick();
  };

  const handleStickyLabel = () => {
    const [variation, preposition] = !isSticky
      ? ['Stick', 'to']
      : ['Unstick', 'from'];
    return `${variation} row ${preposition} ${allowedToStickyPosition}`;
  };

  const handleHover = isOver => {
    const stickyRowOverlay = document.querySelector(
      `#sticky-row-overlay-${id}`
    );
    if (stickyRowOverlay && stickyRowOverlay.dataset) {
      stickyRowOverlay.dataset.overlay = isOver;
    }
  };

  const tooltipLabel = allowedToStickyPosition ? handleStickyLabel : ariaLabel;

  return (
    <Tooltip label={tooltipLabel}>
      <Button
        onClick={handleClick}
        aria-label={ariaLabel}
        data-selected={isSticky}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
      >
        <Icon
          icon={icon}
          size="x-small"
          color={type === 'delete' ? 'var(--color-pink-500)' : 'inherit'}
        />
      </Button>
    </Tooltip>
  );
};

ToolbarButton.propTypes = {
  type: PropTypes.oneOf(Object.keys(buttonTypes)),
  onClick: PropTypes.func,
  row: PropTypes.shape({
    id: PropTypes.string,
    stickyPosition: PropTypes.oneOf(STICKY_OPTIONS),
    isSticky: PropTypes.bool,
  }),
};

export default ToolbarButton;
