import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { Accordion } from '@appcues/sonar';
import useToggle from 'ext/lib/hooks/use-toggle';
import {
  FieldSet,
  Label,
  ControlledInput as Input,
  Select,
} from 'ext/components/ui';
import { Shape as BlockContentShape } from 'entities/block';
import { selectSlate, Shape as SlateEditorShape } from 'entities/slate';
import { selectUserPreferences } from 'entities/user-preferences';
import { parseFromSlate } from 'lib/slate';
import { COLOR_MODES } from 'lib/user-preferences';
import ColorInput from 'components/ColorInput';

import {
  Controls,
  GroupedFieldSet,
  GroupedField,
  useStyleSettings,
  useAccordionClick,
} from 'components/SideBarSettings/Shared';
import {
  CustomFontButton,
  CustomFontModal,
  FontGroupOptions,
  getGroupedOptions,
  getGroupedOptionsValues,
  setCustomFontName,
} from 'components/SideBarSettings/Shared/Fonts';
import InputWithValidation from 'components/InputWithValidation';

const [LIGHT, DARK] = COLOR_MODES;

export function FontStyle({
  content,
  onChange,
  hideLineHeight = false,
  slate,
  colorMode,
}) {
  const trackAccordion = useAccordionClick();
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { id, style } = content ?? {};
  const groupedOptions = getGroupedOptions();
  const groupedOptionsValues = getGroupedOptionsValues(groupedOptions);

  const { id: slateId, editor: slateEditor } = slate;
  const isSlateContext = slateId === id;
  const [{ children } = {}] =
    (isSlateContext && slateEditor?.getFragment?.()) || [];
  const [textFragment] = children ?? [];

  const handleChange = useCallback(
    patch => {
      onChange({ style: patch });
    },
    [onChange]
  );

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange,
    style,
    colorMode,
  });

  const handleCustomFontButtonClick = () => {
    toggleIsModalOpen();
    setIsMenuOpen(false);
  };

  const handleAddFont = value => {
    handleChangeFor.fontName({ value });
    setCustomFontName(value);
  };

  const handleValueFor = {
    fontName: groupedOptionsValues.find(({ value }) =>
      textFragment?.fontName
        ? textFragment.fontName === value
        : valueFor.fontName === value
    ),
    fontSize: textFragment?.fontSize || valueFor.fontSize,
    foregroundColor:
      textFragment?.foregroundColor?.[colorMode] ||
      textFragment?.foregroundColor?.[LIGHT] ||
      valueFor.foregroundColor,
  };

  // If there's a custom font for the text that's not currently in the options (ie in localStorage), add it
  if (handleValueFor.fontName === undefined) {
    setCustomFontName(textFragment?.fontName ?? valueFor.fontName);
  }

  const handleSlateChange = debounce((key, value) => {
    slateEditor.addMark(`${key}`, value);

    const { text, spans } = parseFromSlate(slateEditor.children);
    // If we have only one slate span, and its content is the same as the text
    // we apply the slate style to the text for consistent backwards compatibility
    const compatibilityStyle = spans[0]?.text === text && spans[0]?.style;

    onChange({
      text,
      spans,
      ...(compatibilityStyle && {
        style: { ...style, ...compatibilityStyle },
      }),
    });
  }, 500);

  const handleSlateChangeFor = {
    fontName: event => handleSlateChange('fontName', event.value),
    fontSize: ({ target: { valueAsNumber } }) =>
      handleSlateChange('fontSize', valueAsNumber),
    foregroundColor: value => {
      const currentColor =
        textFragment?.foregroundColor || style?.foregroundColor;
      let updatedValue = { ...currentColor, [colorMode]: value };

      // If the current colorMode is dark, and there's no light color set,
      // set the light color to the current color
      if (colorMode === DARK && !currentColor?.[LIGHT]) {
        updatedValue = {
          ...updatedValue,
          [LIGHT]: value,
        };
      }

      handleSlateChange('foregroundColor', updatedValue);
    },
  };

  const handleOnChange = (key, event) =>
    isSlateContext
      ? handleSlateChangeFor[key](event)
      : handleChangeFor[key](event);

  return (
    <Accordion.Item value="font-style">
      <Accordion.Header>
        <Accordion.Trigger
          onClick={() => trackAccordion('Text', 'Font & style')}
        >
          Font & style
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Label htmlFor="font">Font</Label>
            <Select
              id="font"
              options={groupedOptions}
              formatGroupLabel={FontGroupOptions}
              placeholder="Select font"
              value={handleValueFor.fontName}
              onChange={event => handleOnChange('fontName', event)}
              portal
              menuIsOpen={isMenuOpen}
              onMenuOpen={() => setIsMenuOpen(true)}
              onMenuClose={() => setIsMenuOpen(false)}
              menuButton={
                <CustomFontButton onClick={handleCustomFontButtonClick} />
              }
            />
            <CustomFontModal
              onAddFont={handleAddFont}
              onClose={toggleIsModalOpen}
              visible={isModalOpen}
            />
          </FieldSet>

          <GroupedFieldSet>
            <GroupedField half={!hideLineHeight}>
              <Label htmlFor="font-size">Font size</Label>
              <InputWithValidation
                id="font-size"
                value={handleValueFor.fontSize}
                onChange={event => handleOnChange('fontSize', event)}
                type="number"
                min="0"
              />
            </GroupedField>

            {!hideLineHeight && (
              <GroupedField half>
                <Label htmlFor="line-height">Line height</Label>
                <Input
                  id="line-height"
                  defaultValue={valueFor.lineHeight}
                  onChange={handleChangeFor.lineHeight}
                  type="number"
                  placeholder="Auto"
                  min="0"
                />
              </GroupedField>
            )}
          </GroupedFieldSet>

          <FieldSet>
            <Label htmlFor="font-style-color-input">Color</Label>
            <ColorInput
              id="font-style-color-input"
              color={handleValueFor.foregroundColor}
              onChange={event => handleOnChange('foregroundColor', event)}
            />
          </FieldSet>
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

FontStyle.propTypes = {
  content: BlockContentShape,
  onChange: PropTypes.func,
  hideLineHeight: PropTypes.bool,
  slate: SlateEditorShape,
  colorMode: PropTypes.oneOf(COLOR_MODES),
};

const mapStateToProps = state => ({
  slate: selectSlate(state),
  colorMode: selectUserPreferences(state).colorMode,
});

export default connect(mapStateToProps)(FontStyle);
