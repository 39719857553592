import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { Icon } from '@appcues/sonar';
import search from 'ext/lib/search';
import Fade from 'ext/components/Fade';
import { Button, Input, Search } from 'ext/components/ui';
import { selectHasMultipleAccounts } from 'ext/entities/accounts';
import { selectCurrentAccountName } from 'ext/lib/selectors';
import { sortList } from 'ext/lib/sort-list';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import {
  focus,
  selectExperiencesSummary,
  Shape as ExperienceShape,
} from 'entities/experiences';
import { LOADING, LOADED, selectStatusByEntity } from 'entities/status';
import Spinner from 'components/Spinner';
import ListItem from './ListItem';
import { FlowListEmpty, NoFlowsFound } from './StatusMessages';
import {
  Container,
  Heading,
  Loading,
  List,
  ListWrapper,
  Menu,
  Section,
} from './styled';

export const FlowSelector = forwardRef(
  (
    {
      flows = {},
      accountName = '',
      onClose,
      onCreate,
      onLoad,
      onSelect,
      selected,
      visible = true,
      status,
    },
    ref
  ) => {
    const { track } = useAnalytics();

    const [query, setQuery] = useState('');

    useEffect(() => {
      if (onLoad) {
        onLoad();
      }
    }, [onLoad]);

    const flowsList = Object.values(flows);

    const handleChange = ({ target: { value } }) => {
      setQuery(value);
    };

    const handleSelect = id => {
      track('Mobile Builder interaction', {
        name: 'Selected Flow',
        component: 'FlowSelector',
      });
      onSelect(id);
      onClose();
    };

    const handleCreate = () => {
      track('Mobile Builder view', {
        name: 'Opened Create New Flow Modal',
        component: 'FlowSelector',
      });
      onCreate();
      onClose();
    };

    const processedFlows = useMemo(() => {
      const sorted = sortList(flowsList, selected);

      return search(sorted, 'name', query);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flows, selected, query]);

    return (
      <Container ref={ref}>
        <Fade from="bottom" visible={visible} onExited={() => setQuery('')}>
          <Menu>
            <Section justify="flex-start">
              <Heading>
                {accountName ? `Flows in ${accountName}` : 'Your mobile flows'}
              </Heading>
            </Section>

            {flowsList.length > 5 && (
              <Search wrapped>
                <Icon icon={faSearch} size="small" />
                <Input
                  onChange={handleChange}
                  placeholder="Search your flows..."
                  role="textbox"
                  value={query}
                />
              </Search>
            )}

            <ListWrapper>
              {status === LOADING && (
                <Loading>
                  <Spinner />
                </Loading>
              )}

              {status === LOADED && flowsList.length === 0 && <FlowListEmpty />}

              {status === LOADED &&
                flowsList.length > 0 &&
                processedFlows.length === 0 && <NoFlowsFound />}

              {status === LOADED && processedFlows.length > 0 && (
                <List role="list">
                  {processedFlows.map(flow => (
                    <ListItem
                      key={flow.id}
                      flow={flow}
                      isSelected={flow.id === selected}
                      onClick={handleSelect}
                    />
                  ))}
                </List>
              )}
            </ListWrapper>

            <Button kind="secondary" onClick={handleCreate}>
              Create New Flow
            </Button>
          </Menu>
        </Fade>
      </Container>
    );
  }
);

FlowSelector.propTypes = {
  flows: PropTypes.objectOf(ExperienceShape),
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onLoad: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.string,
  visible: PropTypes.bool,
  accountName: PropTypes.string,
  status: PropTypes.oneOf([LOADING, LOADED]),
};

const mapStateToProps = state => {
  const multiple = selectHasMultipleAccounts(state);
  const name = selectCurrentAccountName(state);

  return {
    flows: selectExperiencesSummary(state),
    accountName: multiple ? name : null,
    status: selectStatusByEntity(state, 'experiences-summary'),
  };
};

const mapDispatchToProps = {
  onSelect: focus,
};

const options = { forwardRef: true };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  options
)(FlowSelector);
