import styled from 'styled-components';
import { Button } from '@appcues/sonar';

export const UploaderBox = styled.button<{
  status:
    | 'warning'
    | 'idle'
    | 'focus'
    | 'error'
    | 'uploading'
    | 'showing_image';
}>`
  width: 100%;
  padding: var(--spacing-regular);
  border-radius: var(--border-radius-small);

  // Focus in this case changes background and border color no need for outline
  outline: none;

  // Applies to idle or loading by default
  background: var(--background-default-base);
  border: 1px dotted var(--border-input-default);

  // Override dropdown menu button styles
  &&[data-state='open'] {
    background: var(--background-brand-base);
    border: 1px dotted var(--border-input-default);
  }

  ${props =>
    props.status !== 'showing_image' &&
    `    
      cursor: pointer;

      &:hover,
      &:focus {
        background: var(--background-brand-base);
      }
    `}

  ${props =>
    props.status === 'warning' &&
    `
    background: var(--background-warning);
    border: 1px dashed var(--border-warning);

    &:hover,
    &:focus {
      background: var(--background-warning);
      border: 1px dashed var(--border-warning);
    }
  `}

  ${props =>
    props.status === 'error' &&
    `
    background: var(--background-error-base);
    border: 1px dashed var(--border-error);

    &:hover,
    &:focus {
      background: var(--background-error-base);
      border: 1px dashed var(--border-error);
    }
  `}

  ${props =>
    props.status === 'focus' &&
    `
    background: var(--color-blue-100);
  `}

  > input {
    position: absolute;
    height: 0;
    width: 0;
    visibility: hidden;
  }
`;

export const BackButton = styled(Button)`
  && {
    padding: 2px var(--spacing-regular);

    &:hover:not(:active):not([disabled]),
    &:focus:not(:active):not([disabled]) {
      background: transparent;
    }
  }
`;
