import { viewportOptions } from 'lib/device';
import { DEFAULT_LOCALE } from 'lib/localization';

/**
 * User preferences options
 *
 * @constant
 */
export const USER_PREFERENCES = {
  colorMode: ['light', 'dark'],
  direction: ['ltr', 'rtl'],
  orientation: ['portrait', 'landscape'],
  viewport: viewportOptions,
  embed: {
    max: '2048',
    min: '48',
    default: '380',
  },
  locale: DEFAULT_LOCALE,
};

export const COLOR_MODES = USER_PREFERENCES.colorMode;
export const DIRECTIONS = USER_PREFERENCES.direction;
export const ORIENTATIONS = USER_PREFERENCES.orientation;
export const VIEWPORTS = USER_PREFERENCES.viewport;
export const EMBED = USER_PREFERENCES.embed;
export const LOCALE = USER_PREFERENCES.locale;
