import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { Icon } from '@appcues/sonar';
import useClickOutside from 'ext/lib/hooks/use-click-outside';
import { focus } from 'entities/block';
import { selectSelected } from 'entities/selected';
import {
  selectStepChild,
  ContentShape,
  StyleShape,
} from 'entities/step-children';
import { getParentBlock } from 'lib/block';
import { transformStyles } from 'components/Editor/Primitives';

const ErrorMessageWrapper = styled.div`
  display: none;
  align-items: center;
  flex-direction: column;
  color: var(--foreground-inverse);
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const ErrorMessage = styled.p`
  text-align: center;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &:not(:has(iframe)) {
    ${ErrorMessageWrapper} {
      display: flex;
      background: var(--gradient-neutral);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      justify-content: center;
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Content = styled.div`
  ${({ intrinsicSize, style }) =>
    intrinsicSize &&
    css`
      aspect-ratio: ${intrinsicSize.width} / ${intrinsicSize.height};

      /* This is needed to make the video as vertically centered */
      top: calc(
        50% - (${style.marginTop || '0px'} + ${style.marginBottom || '0px'}) / 2
      );
      position: relative;
      transform: translateY(-50%);

      > iframe {
        width: 100%;
        aspect-ratio: ${intrinsicSize.width} / ${intrinsicSize.height};
      }
    `}
`;

const Placeholder = styled.div`
  background: var(--noreaster);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
`;

function VideoContainer({
  id,
  style,
  embed,
  intrinsicSize,
  content,
  selectedBlock,
  onClick,
}) {
  const $overlay = useRef();
  const styles = transformStyles(style);
  const [isEnabled, setIsEnabled] = useState(false);
  const { id: parentId } = getParentBlock(content, id) ?? {};

  // This enables to select the video at the first click
  // after that you can control the video (play, move, etc) or delete
  const enableVideoInteraction = () => {
    $overlay.current.style = 'pointer-events: none;';
    setIsEnabled(true);
  };

  useEffect(() => {
    if (parentId === selectedBlock) {
      enableVideoInteraction();
    }
  }, [parentId, selectedBlock, embed]);

  const handleOnClick = event => {
    if (id !== parentId) {
      event.stopPropagation();
      onClick(parentId);
    }
  };

  // When unselecting the video, we want to reset the overlay
  // so we're able to select again without playing the video
  const handleClickOutside = () => {
    $overlay.current.style = 'pointer-events: auto;';
    setIsEnabled(false);
  };

  useClickOutside([$overlay], isEnabled && handleClickOutside);

  return embed ? (
    <Wrapper onClick={handleOnClick}>
      <Overlay ref={$overlay} />
      <ErrorMessageWrapper>
        <Icon icon={faVideo} size="x-large" />
        <ErrorMessage>Preview on your device to test the video.</ErrorMessage>
      </ErrorMessageWrapper>
      <Content
        id={id}
        style={styles}
        intrinsicSize={intrinsicSize}
        dangerouslySetInnerHTML={{ __html: embed }}
      />
    </Wrapper>
  ) : (
    <Placeholder>
      <Overlay ref={$overlay} />
      Add your Video Script
    </Placeholder>
  );
}

VideoContainer.propTypes = {
  id: PropTypes.string.isRequired,
  style: StyleShape,
  embed: PropTypes.string,
  intrinsicSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  content: ContentShape,
  selectedBlock: PropTypes.string,
  onClick: PropTypes.func,
};

const mapStateToProps = state => {
  const { stepChild: stepId, block: selectedBlock } =
    selectSelected(state) ?? {};
  const { content } = selectStepChild(state, stepId) ?? {};

  return { content, selectedBlock };
};

const mapDispatchToProps = {
  onClick: focus,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoContainer);
