import { ImageType } from 'types';

const getFileInfo = (file: File | ImageType) => {
  if ('type' in file) {
    return {
      type: file.type?.toLowerCase(),
      extension: `.${file?.name?.toLowerCase().split('.').pop()}`,
    };
  }

  return {
    extension: file.format,
  };
};

export const validateFormat = (
  file: File | ImageType,
  accept: string
): boolean => {
  if (!file || !accept) {
    return false;
  }

  const acceptedTypes = accept
    .split(',')
    .map(format => format.trim().toLowerCase());

  const { type, extension } = getFileInfo(file);

  const isValid = acceptedTypes.some(acceptedType => {
    if (type === acceptedType) {
      return true;
    }

    if (type?.startsWith('image/') && acceptedType === 'image/*') {
      return true;
    }

    const acceptedExtension = acceptedType.split('/')[1];
    if (acceptedExtension === extension) {
      return true;
    }

    if (acceptedExtension === '*') {
      return true;
    }

    return false;
  });

  return isValid;
};

export const validateDimension = (
  image: ImageBitmap | ImageType,
  maxDimension?: number
): boolean => {
  return (
    !maxDimension ||
    (image.width <= maxDimension && image.height <= maxDimension)
  );
};

export const validateSize = (
  file: File | ImageType,
  maxSize: number
): boolean => {
  return !maxSize || file.size <= maxSize * 1024 * 1024;
};
