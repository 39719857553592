export const EmptyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="160"
      height="160"
    >
      <g clipPath="url(#a)">
        <path
          fill="color(display-p3 .9569 .9843 1)"
          d="M80.722 160.838c44.182 0 80-35.817 80-80 0-44.182-35.818-80-80-80-44.183 0-80 35.818-80 80 0 44.183 35.817 80 80 80Z"
        />
        <path
          fill="#fff"
          stroke="color(display-p3 .3608 .3608 1)"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.105 94.555a.14.14 0 0 1 .106-.094l1.874-.512a.14.14 0 0 0 .105-.135.144.144 0 0 0-.105-.14l-1.874-.516a.152.152 0 0 1-.101-.102l-.517-1.875a.14.14 0 0 0-.135-.106.144.144 0 0 0-.14.106l-.511 1.875a.147.147 0 0 1-.097.102l-1.883.512a.135.135 0 0 0-.105.135.14.14 0 0 0 .106.136l1.882.52a.153.153 0 0 1 .101.102l.508 1.875a.148.148 0 0 0 .14.106.14.14 0 0 0 .134-.106l.512-1.883Z"
        />
        <path
          fill="color(display-p3 .9137 .9686 1)"
          d="M15.722 127.838h130v40.405h-130z"
        />
        <path
          stroke="color(display-p3 .3608 .3608 1)"
          strokeLinecap="round"
          strokeWidth=".878"
          d="M24.945 128.278h111.554m4.391 0h4.392m-129.121 0h4.392"
        />
      </g>
      <path
        fill="#fff"
        stroke="color(display-p3 .3608 .3608 1)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M130.215 42.568a.413.413 0 0 1 .31-.273l5.482-1.498a.407.407 0 0 0 .309-.397.422.422 0 0 0-.309-.409l-5.482-1.51a.448.448 0 0 1-.297-.297l-1.51-5.486a.407.407 0 0 0-.396-.31.424.424 0 0 0-.408.31l-1.497 5.486a.437.437 0 0 1-.285.297l-5.507 1.498a.395.395 0 0 0-.309.397.41.41 0 0 0 .309.396l5.507 1.523a.448.448 0 0 1 .297.297l1.485 5.486a.433.433 0 0 0 .408.31.406.406 0 0 0 .396-.31l1.497-5.51Z"
      />
      <path
        fill="url(#b)"
        stroke="color(display-p3 .3608 .3608 1)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M69.959 17.271a.519.519 0 0 1 .393-.346l6.966-1.904a.519.519 0 0 0 .393-.504.535.535 0 0 0-.393-.52l-6.966-1.92a.567.567 0 0 1-.378-.377l-1.918-6.972a.519.519 0 0 0-.504-.394.534.534 0 0 0-.519.394L65.131 11.7a.551.551 0 0 1-.362.378l-6.998 1.904a.504.504 0 0 0-.393.504.52.52 0 0 0 .393.503l6.998 1.936a.566.566 0 0 1 .377.378l1.887 6.972a.551.551 0 0 0 .52.394.519.519 0 0 0 .503-.394l1.903-7.004Z"
      />
      <path
        fill="#fff"
        stroke="color(display-p3 .3608 .3608 1)"
        d="m58.399 111.856-.003.001a1.416 1.416 0 0 1-1.736-.996v-.002L42.394 56.753l-.002-.007a1.4 1.4 0 0 1 .995-1.731L95.28 41.383a1.417 1.417 0 0 1 1.724 1.01l12.224 46.22c1.342 5.075-1.686 10.275-6.761 11.615l-44.068 11.628Z"
      />
      <path
        fill="#fff"
        stroke="color(display-p3 .3608 .3608 1)"
        strokeLinejoin="round"
        d="M102.07 86.577 61.676 97.242a1.933 1.933 0 0 1-2.4-1.35l-8.999-34.095a1.916 1.916 0 0 1 1.367-2.333l38.595-10.232a1.933 1.933 0 0 1 2.35 1.366l9.481 35.979Z"
      />
      <path
        fill="#fff"
        stroke="color(display-p3 .3608 .3608 1)"
        d="M63.776 61.547h54.159c.644 0 1.167.522 1.167 1.166v56.459c0 .644-.523 1.167-1.167 1.167h-54.16a1.167 1.167 0 0 1-1.166-1.167V62.713c0-.644.523-1.166 1.167-1.166Z"
      />
      <path
        fill="color(display-p3 .8431 .8431 1)"
        stroke="color(display-p3 .3608 .3608 1)"
        d="M110.972 67.713H71.176a2.14 2.14 0 0 0-2.143 2.138V105a2.14 2.14 0 0 0 2.143 2.138h39.796a2.14 2.14 0 0 0 2.143-2.138V69.851a2.14 2.14 0 0 0-2.143-2.138Z"
      />
      <mask
        id="c"
        width="44"
        height="39"
        x="69"
        y="68"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
      >
        <path
          fill="color(display-p3 .8431 .8431 1)"
          stroke="color(display-p3 .3608 .3608 1)"
          d="M71.176 68.713h39.796a1.14 1.14 0 0 1 1.143 1.138V105a1.14 1.14 0 0 1-1.143 1.138H71.176A1.14 1.14 0 0 1 70.033 105V69.851c0-.627.51-1.138 1.143-1.138Z"
        />
      </mask>
      <g mask="url(#c)">
        <path
          fill="#fff"
          stroke="color(display-p3 .3608 .3608 1)"
          strokeLinejoin="round"
          d="M119.168 107.22s-9.782-8.959-11.299-16.25a2.233 2.233 0 0 0-.796-1.294 2.31 2.31 0 0 0-1.453-.504h-9.666a2.311 2.311 0 0 0-1.488.512c-.418.338-.7.812-.795 1.335-1.383 7.275-10.165 16.201-10.165 16.201h35.662Z"
        />
        <mask id="d" fill="#fff">
          <path
            fillRule="evenodd"
            d="M67.173 83.35c-1.196 0-2.297-.8-2.297-1.996a7.319 7.319 0 0 1 13.904-3.197.567.567 0 0 0 .512.314c1.284 0 2.434.574 3.207 1.48.608.713 1.622 1.181 2.56 1.181 2.052 0 1.223 2.218-.829 2.218H67.173Z"
            clipRule="evenodd"
          />
        </mask>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M67.173 83.35c-1.196 0-2.297-.8-2.297-1.996a7.319 7.319 0 0 1 13.904-3.197.567.567 0 0 0 .512.314c1.284 0 2.434.574 3.207 1.48.608.713 1.622 1.181 2.56 1.181 2.052 0 1.223 2.218-.829 2.218H67.173Z"
          clipRule="evenodd"
        />
        <path
          fill="color(display-p3 .3608 .3608 1)"
          d="m78.78 78.157-.899.437.9-.437Zm3.72 1.793-.761.65.76-.65Zm-16.623 1.404a6.32 6.32 0 0 1 6.319-6.32v-2a8.319 8.319 0 0 0-8.319 8.32h2Zm6.319-6.32a6.32 6.32 0 0 1 5.686 3.56l1.799-.875a8.32 8.32 0 0 0-7.485-4.684v2Zm7.097 4.437c.979 0 1.855.436 2.446 1.129l1.521-1.299a5.205 5.205 0 0 0-3.967-1.83v2Zm4.938 2.879H67.173v2H84.23v-2Zm.829-.218c.36 0 .353.1.266-.001-.114-.133-.07-.262-.079-.24-.012.034-.078.15-.26.263a1.422 1.422 0 0 1-.756.196v2c1.36 0 2.51-.74 2.89-1.76.199-.531.193-1.215-.278-1.763-.445-.517-1.119-.695-1.783-.695v2Zm-7.178-3.538c.27.556.832.877 1.41.877v-2c.148 0 .307.081.389.248l-1.799.875Zm3.857 2.006c.798.935 2.09 1.532 3.32 1.532v-2c-.644 0-1.38-.34-1.799-.83L81.738 80.6Zm-17.862.754c0 1.921 1.74 2.996 3.297 2.996v-2c-.835 0-1.297-.526-1.297-.996h-2Z"
          mask="url(#d)"
        />
        <mask id="e" fill="#fff">
          <path
            fillRule="evenodd"
            d="M77.652 79.857c-1.196 0-2.297-.8-2.297-1.996a7.319 7.319 0 0 1 13.904-3.197.567.567 0 0 0 .512.313c1.284 0 2.433.575 3.206 1.48.61.714 1.623 1.182 2.56 1.182 2.052 0 1.224 2.218-.828 2.218H77.652Z"
            clipRule="evenodd"
          />
        </mask>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M77.652 79.857c-1.196 0-2.297-.8-2.297-1.996a7.319 7.319 0 0 1 13.904-3.197.567.567 0 0 0 .512.313c1.284 0 2.433.575 3.206 1.48.61.714 1.623 1.182 2.56 1.182 2.052 0 1.224 2.218-.828 2.218H77.652Z"
          clipRule="evenodd"
        />
        <path
          fill="color(display-p3 .3608 .3608 1)"
          d="m89.26 74.664.899-.438-.9.438Zm3.717 1.793-.76.65.76-.65Zm-16.622 1.404a6.32 6.32 0 0 1 6.319-6.32v-2a8.319 8.319 0 0 0-8.319 8.32h2Zm6.319-6.32a6.32 6.32 0 0 1 5.686 3.56l1.799-.875a8.32 8.32 0 0 0-7.485-4.684v2Zm7.097 4.436c.979 0 1.855.437 2.446 1.13l1.521-1.299a5.205 5.205 0 0 0-3.967-1.83v2Zm4.938 2.88H77.652v2h17.057v-2Zm.828-.218c.362 0 .353.099.267-.002-.114-.132-.07-.262-.079-.24-.012.035-.079.15-.26.264a1.423 1.423 0 0 1-.756.196v2c1.36 0 2.51-.74 2.89-1.76.199-.531.193-1.215-.279-1.764-.444-.516-1.118-.694-1.782-.694v2ZM88.36 75.1c.27.555.832.876 1.41.876v-2c.148 0 .307.082.389.25l-1.799.874Zm3.857 2.005c.798.936 2.09 1.533 3.32 1.533v-2c-.644 0-1.38-.34-1.799-.831l-1.521 1.298Zm-17.862.755c0 1.921 1.74 2.996 3.297 2.996v-2c-.835 0-1.297-.526-1.297-.996h-2Z"
          mask="url(#e)"
        />
        <path
          fill="#fff"
          stroke="color(display-p3 .3608 .3608 1)"
          d="M108.622 76.364a4.157 4.157 0 1 1-8.314 0 4.157 4.157 0 0 1 8.314 0Z"
        />
        <path
          fill="#fff"
          stroke="color(display-p3 .3608 .3608 1)"
          strokeLinejoin="round"
          d="M107.376 90.918c0 .965-2.998 1.747-6.695 1.747-3.698 0-6.696-.782-6.696-1.747 0-1.746 2.998-1.746 6.696-1.746 3.697 0 6.695 0 6.695 1.746Z"
        />
      </g>
      <defs>
        <linearGradient
          id="b"
          x1="57.377"
          x2="77.711"
          y1="14.502"
          y2="14.502"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="color(display-p3 .3608 .3608 1)" />
          <stop offset="1" stopColor="color(display-p3 .5373 .3765 1)" />
        </linearGradient>
        <clipPath id="a">
          <rect
            width="160"
            height="160"
            x=".722"
            y=".838"
            fill="#fff"
            rx="80"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
