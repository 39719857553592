import {
  ChangeEvent,
  DragEvent,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons/faCirclePlus';
import { faUpload } from '@fortawesome/pro-regular-svg-icons/faUpload';
import { faImages } from '@fortawesome/free-regular-svg-icons/faImages';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation';
import { faHexagonExclamation } from '@fortawesome/pro-solid-svg-icons/faHexagonExclamation';
import { DropdownMenu, Icon, Row, Stack, Text } from '@appcues/sonar';
import { validateFormat, validateSize } from 'components/validators';
import { useImagery } from 'context';
import { SelectedImage } from './SelectedImage';
import { LoadingIcon } from './LoadingIcon';
import { reducer, initialState } from './state';
import { UploaderType } from './types';
import { validateFileDimension } from './file-validator';
import { BackButton, UploaderBox } from './styled';

export const Uploader = ({
  title = 'Add an image',
  description = 'JPEG, PNG and SVG - 1MB Max',
  accept = 'image/*',
  maxSize = 1,
  maxDimension,
  selectedImage,
  disableMenu,
  onUpload,
  onClean,
  ...htmlAttrs
}: UploaderType) => {
  const { setIsGalleryOpen } = useImagery();

  const inputId = useMemo(() => `imagery-uploader-${crypto.randomUUID()}`, []);
  const inputRef = useRef<HTMLInputElement>(null);

  const [state, dispatch] = useReducer(
    reducer,
    selectedImage
      ? { status: 'showing_image', image: selectedImage }
      : initialState
  );

  useEffect(() => {
    if (selectedImage) {
      dispatch({
        type: 'IMAGE_SELECTED',
        image: selectedImage,
      });
    }
  }, [selectedImage]);

  const [openDropdownMenu, setDropdownOpen] = useState(false);

  const validateImage = async (file: File): Promise<boolean> => {
    if (!validateFormat(file, accept)) {
      dispatch({
        type: 'INVALIDATE_IMAGE',
        warningMessage: `Format ${file.type} is not supported`,
      });
      return false;
    }

    if (!validateSize(file, maxSize)) {
      dispatch({
        type: 'INVALIDATE_IMAGE',
        warningMessage: `Size must not exceed ${maxSize}MB`,
      });
      return false;
    }

    if (!(await validateFileDimension(file, maxDimension))) {
      dispatch({
        type: 'INVALIDATE_IMAGE',
        warningMessage: `Dimensions must not exceed ${maxDimension}px`,
      });
      return false;
    }

    return true;
  };

  const handleOnUpload = async (image: File) => {
    try {
      const isImageValid = await validateImage(image);
      if (!isImageValid) return;

      dispatch({ type: 'START_UPLOAD' });
      const result = await onUpload(image);

      if (!result) {
        throw new Error('Upload failed');
      }

      dispatch({
        type: 'UPLOAD_FINISHED',
        image: result?.url,
      });
    } catch (error: unknown) {
      dispatch({
        type: 'UPLOAD_ERROR',
        errorMessage:
          error instanceof Error ? error.message : 'Unknown error occurred',
      });
    }
  };

  const handleOnDrop = async (event: DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files.length === 0) return;
    await handleOnUpload(event.dataTransfer.files[0]);
  };

  const handleDragOver = (event: DragEvent<HTMLButtonElement>) => {
    event.preventDefault();

    dispatch({
      type: 'DRAG_OVER',
    });
  };

  const handleDragLeave = (event: DragEvent<HTMLButtonElement>) => {
    event.preventDefault();

    dispatch({
      type: 'DRAG_LEAVE',
    });
  };

  const handleOnChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) return;
    await handleOnUpload(event.target.files[0]);
  };

  const handleOnUploadSelect = () => {
    inputRef.current?.click();
  };

  const handleClean = () => {
    dispatch({
      type: 'CLEAN_FILE',
    });

    onClean?.();
  };

  const handleBack = () => {
    dispatch({
      type: 'RESET_STATUS',
    });
  };

  if (state.status === 'showing_image') {
    return (
      <UploaderBox status={state.status} as="div">
        <SelectedImage image={state.image} handleClean={handleClean} />
      </UploaderBox>
    );
  }

  if (state.status === 'error') {
    return (
      <UploaderBox status={state.status} as="div">
        <Stack spacing="small" xAlign="center">
          <Row spacing="small" yAlign="center">
            <Icon icon={faHexagonExclamation} colorToken="foreground-error" />
            <Text weight="bold" colorToken="foreground-error">
              {state.errorMessage}
            </Text>
          </Row>
          <BackButton variant="tertiary" onClick={handleBack}>
            Back
          </BackButton>
        </Stack>
      </UploaderBox>
    );
  }

  if (state.status === 'warning') {
    return (
      <UploaderBox status={state.status} as="div">
        <Stack spacing="small" xAlign="center">
          <Row spacing="small" yAlign="center">
            <Icon
              icon={faTriangleExclamation}
              colorToken="foreground-warning"
            />
            <Text weight="bold" colorToken="foreground-warning">
              {state.warningMessage}
            </Text>
          </Row>
          <BackButton variant="tertiary" onClick={handleBack}>
            Back
          </BackButton>
        </Stack>
      </UploaderBox>
    );
  }

  if (state.status === 'uploading') {
    return (
      <UploaderBox status={state.status} as="div">
        <Stack spacing="small" xAlign="center">
          <LoadingIcon />
          <Text weight="bold" colorToken="foreground-primary">
            Uploading file...
          </Text>
        </Stack>
      </UploaderBox>
    );
  }

  return (
    <DropdownMenu.Root
      open={!disableMenu && openDropdownMenu}
      onOpenChange={setDropdownOpen}
    >
      <DropdownMenu.Trigger asChild>
        <UploaderBox
          // eslint-disable-next-line @appcues/jsx-props-no-spreading
          {...htmlAttrs}
          status={state.status}
          onDrop={handleOnDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onClick={disableMenu ? handleOnUploadSelect : undefined}
          type="button"
        >
          <input
            ref={inputRef}
            id={inputId}
            onChange={handleOnChange}
            accept={accept}
            type="file"
            aria-hidden
          />

          <Stack spacing="small" xAlign="center">
            <Row spacing="small" yAlign="center">
              <Icon icon={faCirclePlus} colorToken="foreground-primary" />
              <Text weight="bold" colorToken="foreground-primary">
                {title}
              </Text>
            </Row>
            <Text colorToken="foreground-input-placeholder">{description}</Text>
          </Stack>
        </UploaderBox>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content side="bottom">
          <DropdownMenu.Item onClick={handleOnUploadSelect}>
            <Row spacing="small" yAlign="center">
              <Icon icon={faUpload} colorToken="foreground-primary" /> Upload
              image
            </Row>
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => setIsGalleryOpen(true)}>
            <Row spacing="small" yAlign="center">
              <Icon icon={faImages} colorToken="foreground-primary" />
              Select from gallery
            </Row>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
