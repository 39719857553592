import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  selectStepChild,
  update,
  ContentShape,
  StyleShape,
} from 'entities/step-children';
import { selectSelected } from 'entities/selected';
import { BlockContentShape } from 'entities/block';
import { selectPreview } from 'entities/user-interface';
import {
  BOX,
  TEXT,
  RATING_EMOJI,
  RATING_NUMBER,
  RATING_STAR,
  BLOCK_MODES,
  updateElement,
} from 'lib/block';
import { ERROR_COLOR, ERROR_MESSAGE, SELECTED_STATE } from 'lib/user-interface';
import { COLOR_MODES } from 'lib/user-preferences';
import { mergeProperties } from 'utils/object-handler';
import {
  RatingOption,
  ErrorMessage,
  handleErrorStyle,
  transformStyles,
} from 'components/Editor/Primitives';
import TextContainer from './TextContainer';

const [, VERTICAL, NPS] = BLOCK_MODES[RATING_NUMBER];
const OPTION_SIZE = 56;

const RatingContainer = styled.div`
  ${({ customStyle }) => customStyle}

  width: auto;
`;

const RatingOptionContainer = styled.div`
  display: flex;
  justify-content: center;

  ${({ displayFormat }) =>
    displayFormat === VERTICAL && `flex-direction: column;`}

  ${({ displayFormat, numOfOptions }) =>
    displayFormat === NPS &&
    css`
      width: ${(OPTION_SIZE * numOfOptions) / 2}px;
      margin: auto;
      flex-wrap: wrap;
    `}

  ${({ ratingType }) => ratingType === RATING_EMOJI && `align-items: center;`}
`;

const Rating = ({
  id,
  ratingType,
  label,
  displayFormat,
  options,
  style,
  required,
  errorLabel,
  colorMode,
  selectedPreview,
  content,
  stepId,
  onUpdate,
}) => {
  const styles = transformStyles(style, colorMode);

  const {
    [ERROR_COLOR]: previewErrorColor,
    [ERROR_MESSAGE]: previewErrorMessage,
    [SELECTED_STATE]: previewSelectedColor,
  } = selectedPreview(id);

  const { id: labelId, text: labelText, spans, style: labelStyle } = label;
  const { text: errorMessage, style: errorStyle } = errorLabel;
  const { color: errorColor, ...errorStyles } = transformStyles(
    errorStyle,
    colorMode
  );

  const labelStyles = previewErrorColor
    ? handleErrorStyle({ style: labelStyle, color: errorColor })
    : labelStyle;

  const handleUpdate = (optionIndex, updatedValue) => {
    const updatedOptions = options.map((option, index) => {
      return index === optionIndex
        ? {
            ...mergeProperties(
              option,
              ['content', 'selectedContent'],
              updatedValue
            ),
            value: updatedValue.text,
          }
        : option;
    });

    const updatedContent = updateElement({
      content,
      blockId: id,
      contentChunk: { options: updatedOptions },
    });

    onUpdate(stepId, updatedContent);
  };

  return (
    <RatingContainer id={id} customStyle={styles}>
      <TextContainer
        id={labelId}
        text={labelText}
        spans={spans}
        label={{
          errorColor: labelStyles.color,
          required,
        }}
        style={labelStyles}
        colorMode={colorMode}
      />
      <RatingOptionContainer
        displayFormat={displayFormat}
        ratingType={ratingType}
        numOfOptions={options.length}
      >
        {options.map(
          ({ content: contentOption, selectedContent, value }, index) => (
            <RatingOption
              index={index}
              key={value}
              content={contentOption}
              displayFormat={displayFormat}
              selectedContent={selectedContent}
              previewSelectedColor={previewSelectedColor}
              error={{ previewErrorColor, errorColor }}
              colorMode={colorMode}
              ratingType={ratingType}
              onUpdate={handleUpdate}
            />
          )
        )}
      </RatingOptionContainer>
      {required && previewErrorMessage && (
        <ErrorMessage
          message={errorMessage}
          color={errorColor}
          transformedStyle={errorStyles}
        />
      )}
    </RatingContainer>
  );
};

Rating.propTypes = {
  id: PropTypes.string,
  ratingType: PropTypes.oneOf([RATING_EMOJI, RATING_NUMBER, RATING_STAR]),
  label: BlockContentShape,
  displayFormat: PropTypes.oneOf(BLOCK_MODES[RATING_NUMBER]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      content: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.oneOf([BOX, TEXT]),
        text: PropTypes.string,
        style: StyleShape,
      }),
    })
  ),
  required: PropTypes.bool,
  errorLabel: BlockContentShape,
  style: StyleShape,
  colorMode: PropTypes.oneOf(COLOR_MODES),
  selectedPreview: PropTypes.func,
  content: ContentShape,
  stepId: PropTypes.string,
  onUpdate: PropTypes.func,
};

const mapStateToProps = state => {
  const selectedPreview = blockId => selectPreview(state, blockId);
  const { stepChild: stepId } = selectSelected(state) ?? {};
  const { content } = selectStepChild(state, stepId) ?? {};

  return { stepId, content, selectedPreview };
};

const mapDispatchToProps = {
  onUpdate: update,
};

export default connect(mapStateToProps, mapDispatchToProps)(Rating);
