import { faTrashCan } from '@fortawesome/pro-solid-svg-icons/faTrashCan';
import { Button, Icon, Row, Text } from '@appcues/sonar';
import styled from 'styled-components';

export const TitleText = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  text-align: left;
`;

type Props = {
  image: string;
  handleClean: () => void;
};

export const SelectedImage = ({ image, handleClean }: Props) => {
  return (
    <Row spacing="small" yAlign="center" xAlign="space-between">
      <TitleText weight="bold" colorToken="foreground-primary">
        {image}
      </TitleText>

      <Button
        variant="tertiary"
        iconOnly
        onClick={handleClean}
        aria-label="remove file"
      >
        <Icon
          icon={faTrashCan}
          colorToken="background-button-destructive-default"
        />
      </Button>
    </Row>
  );
};
