import React from 'react';
import {
  buildPath,
  calculateBoxCornerRadius,
  calculateMainRect,
  getPointerPathInfo,
  maxPointerCornerRadius,
} from './helpers';

const hAlign = {
  leading: 'xMin',
  center: 'xMid',
  trailing: 'xMax',
};

const vAlign = {
  top: 'YMin',
  center: 'YMid',
  bottom: 'YMax',
};

/**
 * Generates the SVG Tooltip shape that includes the main rectangle and its pointer
 * @param {Object} tooltipFrame - the width and height of the whole tooltip frame that includes the main rectangle and its pointer
 * @param {Object} tooltipConfig - configuration for the tooltip, including styles
 * @param {Object} actualPointerEdge - the position of the pointer after position is decided based on the available space and preferred position
 * @param {Object} offsetFromCenter - the offset needed to the pointer to be positioned correctly from the targe
 * @param {Object} colorMode - selected colorMode (light or dark)
 * @returns {pointerPathInfo}
 */
export function drawTooltip({
  tooltipFrame,
  tooltipConfig,
  actualPointerEdge,
  offsetFromCenter,
  colorMode,
}) {
  const { style = {}, backgroundContent } = tooltipConfig ?? {};
  const { imageUrl, horizontalAlignment, verticalAlignment } =
    backgroundContent?.style?.backgroundImage ?? {};

  const pointer = {
    offset: offsetFromCenter,
    edge: actualPointerEdge,
    base: !tooltipConfig.hidePointer ? tooltipConfig.pointerBase ?? 16 : 0,
    length: !tooltipConfig.hidePointer ? tooltipConfig.pointerLength ?? 8 : 0,
  };

  pointer.cornerRadius = Math.min(
    tooltipConfig.pointerCornerRadius ?? 0,
    maxPointerCornerRadius(pointer)
  );

  const borderWidth = style?.borderWidth ?? 0;

  const mainRect = calculateMainRect(tooltipFrame, pointer, borderWidth);

  const boxCornerRadius = calculateBoxCornerRadius(
    style?.cornerRadius ?? 0,
    mainRect.height / 2,
    mainRect.width / 2
  );

  const pointerPathInfo = getPointerPathInfo(
    pointer,
    mainRect,
    boxCornerRadius
  );

  const shadowColor =
    style?.shadow?.color[colorMode] || style?.shadow?.color.light;

  const boxStyles = {
    ...(style?.shadow && {
      filter: `drop-shadow(${style.shadow.x}px ${style.shadow.y}px ${style.shadow.radius}px ${shadowColor})`,
    }),
  };

  const path = buildPath(pointerPathInfo, pointer, mainRect, boxCornerRadius);

  const pathStyles = {
    fill: style.backgroundColor?.[colorMode] || style.backgroundColor?.light,
    ...(style.borderWidth &&
      style.borderColor && {
        stroke: style.borderColor[colorMode] || style.borderColor.light,
        strokeWidth: style.borderWidth,
      }),
  };

  const hasBackgroundImage = imageUrl !== undefined;

  // eslint-disable-next-line @appcues/jsx-props-no-spreading
  const tooltipShape = <path d={path.join(' ')} {...pathStyles} />;

  const bgHorizontalAlign = hAlign[horizontalAlignment || 'center'];
  const bgVerticalAlign = vAlign[verticalAlignment || 'center'];

  const backgroundImage = hasBackgroundImage ? (
    <image
      xlinkHref={backgroundContent?.style.backgroundImage.imageUrl}
      width="100%"
      height="100%"
      preserveAspectRatio={`${bgHorizontalAlign}${bgVerticalAlign} slice`}
      clipPath="url(#tooltipShape)"
    />
  ) : null;

  const pointerShape = (
    <g>
      {tooltipShape}
      {backgroundImage}
    </g>
  );

  const withoutPointer = (
    <rect
      x={mainRect.xOrigin}
      y={mainRect.yOrigin}
      width={mainRect.width}
      height={mainRect.height}
      rx={boxCornerRadius}
      // eslint-disable-next-line @appcues/jsx-props-no-spreading
      {...pathStyles}
    />
  );

  const mainContent = tooltipConfig.hidePointer
    ? hasBackgroundImage
      ? pointerShape
      : withoutPointer
    : pointerShape;

  const tooltipSVG = (
    <svg
      aria-label="tooltip shape"
      viewBox={`0 0 ${tooltipFrame.width} ${tooltipFrame.height}`}
      width={`${tooltipFrame.width}`}
      height={`${tooltipFrame.height}`}
      // eslint-disable-next-line @appcues/jsx-props-no-spreading
      {...boxStyles}
      style={{ position: 'absolute' }}
    >
      <defs>
        <clipPath id="tooltipShape">{tooltipShape}</clipPath>
      </defs>
      {mainContent}
    </svg>
  );

  pointerPathInfo.svg = tooltipSVG;

  return pointerPathInfo;
}
