import React from 'react';
import styled, { css } from 'styled-components';
import { ORIENTATIONS, COLOR_MODES } from 'lib/user-preferences';
import devicePortraitOverlay from './device-portrait-overlay.png';
import devicePortraitOverlayDark from './device-portrait-overlay-dark.png';
import deviceLandscapeOverlay from './device-landscape-overlay.png';
import deviceLandscapeOverlayDark from './device-landscape-overlay-dark.png';
import crosshairTargetPlacement from './crosshair-target-placement-icon.png';

const [LIGHT, DARK] = COLOR_MODES;
const [PORTRAIT, LANDSCAPE] = ORIENTATIONS;

const deviceOverlay = {
  [PORTRAIT]: {
    [LIGHT]: devicePortraitOverlay,
    [DARK]: devicePortraitOverlayDark,
  },
  [LANDSCAPE]: {
    [LIGHT]: deviceLandscapeOverlay,
    [DARK]: deviceLandscapeOverlayDark,
  },
};

const CROSSHAIR_CURSOR = `cursor: url(${crosshairTargetPlacement}) 12 12, auto;`;

export const DeviceViewport = styled.div`
  *,
  *::before,
  *::after {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
  }
  position: relative;
  background-size: 100% 100%;
  background-position: center center;
  display: flex;
  flex: 0 0 auto;

  ${({ setOverflow }) => setOverflow && `overflow: hidden;`}

  ${({ scale }) => `transform: scale(${scale});`}

  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
  `};

  ${({ orientation, colorMode, background }) => `
      background-image: url(${
        background || deviceOverlay[orientation][colorMode]
      });
  `}

  rect {
    transition: all 0.5s ease-in-out;
  }

  transition: all 0.5s ease-in-out;
`;

export const DeviceOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate(0, 0);
`;

export const TargetArea = styled.div`
  flex: 1;
  outline: var(--border-width-small) solid var(--border-input-active-outline);

  ${CROSSHAIR_CURSOR}

  ${({ insets }) =>
    `
    height: calc(100% - ${insets.top}px - ${insets.bottom}px );
    margin-top: ${insets.top}px;
    `}
`;

export const RelativePositionInformation = styled.div`
  position: absolute;
  mix-blend-mode: difference;
  top: 0;
  left: 0;
  margin: 10px 15px;
  font-size: var(--font-size-small);
  color: var(--color-neutral-400);
  pointer-events: none;
`;

export const Backdrop = styled.svg`
  width: 100%;
  height: 100%;
`;

const CROSS_ICON_SIZE = 16;
const CROSS_ICON_PADDING = 8;
const CROSS_ICON_OFFSET = CROSS_ICON_SIZE / 2 + CROSS_ICON_PADDING;
const LAST_POSITION_WIDTH = 32;

export const TargetPositionWrapper = styled.div`
  position: absolute;
  mix-blend-mode: difference;

  ${({ top, left, isLastPosition }) => {
    const lastPositionOffset = isLastPosition ? LAST_POSITION_WIDTH : 0;
    return {
      top: `${top - CROSS_ICON_OFFSET}px`,
      left: `${left - CROSS_ICON_OFFSET - lastPositionOffset}px`,
      ...(isLastPosition && {
        pointerEvents: 'none',
        padding: `${CROSS_ICON_PADDING}px`,
      }),
    };
  }}
`;

export const TargetPositionCrossButton = styled.button`
  mix-blend-mode: difference;
  background: transparent;
  border: none;
  margin: 0;
  padding: ${CROSS_ICON_PADDING}px;
  cursor: pointer;
`;

export const TargetLastPosition = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LastPosition = styled.span`
  color: var(--color-neutral-400);
  font-size: var(--font-size-small);
  margin-top: var(--spacing-x-small);
`;

export const CrossIcon = () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title id="crosshair">Crosshair</title>
    <path fill="#99a6bf" d="M16 7v2H0V7z" />
    <path fill="#99a6bf" d="M9 16H7V0h2z" />
  </svg>
);

const selectButtonSize = {
  width: 220,
  height: 50,
};

export const SelectScreenButton = styled.button`
  position: absolute;
  background: transparent;
  border: 1px solid var(--color-neutral-0);
  border-radius: 6px;
  color: var(--color-neutral-0);
  cursor: pointer;
  font-size: var(--font-size-medium);
  width: ${selectButtonSize.width}px;
  height: ${selectButtonSize.height}px;

  &:hover {
    background: var(--color-neutral-0);
    color: var(--color-neutral-500);
  }

  ${({ top, left }) => css`
    top: ${top - selectButtonSize.height / 2}px;
    left: ${left - selectButtonSize.width / 2}px;
  `}
`;

export const SelectorArea = styled.div`
  flex: 1;
`;

export const Selector = styled.div`
  position: absolute;
  outline: 2px solid var(--color-blue-500);
  outline-offset: 1px;

  ${CROSSHAIR_CURSOR}

  :hover {
    background-color: var(--color-blue-100-opacity-50);
  }
`;

export const SelectorDisplayName = styled.div`
  position: relative;
  bottom: 50px;
  width: fit-content;
  margin: auto;
  padding: 8px 16px;
  font-size: var(--regular);
  background: var(--color-neutral-0);
  border: 2px solid var(--color-neutral-200);
  border-radius: 6px;
  font-family: var(--font-family-mono);

  &[aria-hidden='true'] {
    display: none;
  }
`;
