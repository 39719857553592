import styled from 'styled-components';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@appcues/sonar';

const StyledLoadingIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  svg:first-child {
    animation: rotate 1s linear infinite;
    transform-origin: center;
  }

  ${Icon} {
    position: absolute;
  }
`;

export const LoadingIcon = () => (
  <StyledLoadingIcon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M24 12C24 14.5342 23.1977 17.0033 21.7082 19.0534C20.2187 21.1036 18.1183 22.6296 15.7082 23.4127C13.2981 24.1958 10.7019 24.1958 8.2918 23.4127C5.88167 22.6296 3.78133 21.1036 2.2918 19.0534C0.802259 17.0033 -2.21542e-07 14.5342 0 12C2.21543e-07 9.46585 0.80226 6.99675 2.2918 4.94658C3.78133 2.8964 5.88168 1.37042 8.2918 0.587321C10.7019 -0.195774 13.2981 -0.195774 15.7082 0.587322L15.1149 2.41335C13.0904 1.75555 10.9096 1.75555 8.88511 2.41335C6.86061 3.07115 5.09632 4.35298 3.84511 6.07512C2.5939 7.79727 1.92 9.87131 1.92 12C1.92 14.1287 2.5939 16.2027 3.84511 17.9249C5.09632 19.647 6.86061 20.9288 8.88511 21.5866C10.9096 22.2444 13.0904 22.2445 15.1149 21.5866C17.1394 20.9288 18.9037 19.647 20.1549 17.9249C21.4061 16.2027 22.08 14.1287 22.08 12H24Z"
        fill="#DCE4F2"
        id="circlePath"
      />
      <path
        id="movingPath"
        d="M12 0C13.5759 1.8792e-08 15.1363 0.310389 16.5922 0.913446C18.0481 1.5165 19.371 2.40042 20.4853 3.51472C21.5996 4.62902 22.4835 5.95189 23.0866 7.4078C23.6896 8.86371 24 10.4241 24 12L22.08 12C22.08 10.6763 21.8193 9.36551 21.3127 8.14255C20.8061 6.91959 20.0637 5.80838 19.1276 4.87236C18.1916 3.93635 17.0804 3.19386 15.8574 2.68729C14.6345 2.18073 13.3237 1.92 12 1.92L12 0Z"
        fill="#0056A1"
      />
    </svg>
    <Icon icon={faArrowUp} colorToken="foreground-primary" size="x-small" />
  </StyledLoadingIcon>
);
