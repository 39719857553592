import styled from 'styled-components';
import { Icon } from '@appcues/sonar';
import { RadioButton, RadioButtonGroup } from 'ext/components/ui';

export const MainContainer = styled.div``;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--foreground-tertiary);
  padding: 10px;
  margin: 0 -25px;

  ${Icon} {
    cursor: pointer;
  }
`;

export const InputContainer = styled.div`
  align-content: center;
  display: flex;
  position: relative;
`;

export const CurrentColorWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 10px;
`;

export const ColorPickerContainer = styled.div`
  background: var(--color-neutral-0);
  border-radius: 6px;
  box-shadow: var(--level-3);
  overflow: hidden;
  padding: 0 30px;

  > div:first-of-type {
    margin: 0 -30px;
  }
`;

export const ColorPickerTitle = styled.h5`
  font-size: var(--x-small);
  font-weight: var(--bold);
  color: var(--foreground-tertiary);
  text-transform: uppercase;
  margin: 0;
`;

export const Group = styled.div`
  display: flex;
  align-items: center;

  ${RadioButtonGroup} {
    margin-right: 14px;
  }

  ${RadioButton} {
    padding: 2px 6px;
  }
`;
