import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StyleShape } from 'entities/step-children';
import { COLOR_MODES } from 'lib/user-preferences';
import { transformStyles } from 'components/Editor/Primitives';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'center'};
`;

const Btn = styled.button`
  align-self: center;
  display: flex;
  background-color: unset;
  justify-content: center;
  border-style: initial;

  ${({ size }) => size && `width: ${size};`}
`;

export default function Button({ id, style, colorMode, children }) {
  const { justifyContent, width, ...styles } = transformStyles(
    style,
    colorMode
  );

  return (
    <ButtonContainer justifyContent={justifyContent}>
      <Btn id={id} size={width} style={styles} data-testid={id}>
        {children}
      </Btn>
    </ButtonContainer>
  );
}

Button.propTypes = {
  id: PropTypes.string.isRequired,
  style: StyleShape,
  colorMode: PropTypes.oneOf(COLOR_MODES),
  children: PropTypes.node,
};
