import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { Icon } from '@appcues/sonar';
import { faTimes as RegularIcon } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faXmark as ThinIcon } from '@fortawesome/pro-light-svg-icons/faXmark';
import { SKIPPABLE, SKIPPABLE_OPTIONS } from 'lib/trait';
import { selectSelected } from 'entities/selected';
import { SkippableShape } from 'entities/step-groups';
import { focus } from 'entities/trait';
import { COLOR_MODES } from 'lib/user-preferences';
import { transformStyles } from 'components/Editor/Primitives';

const DEFAULT_SIZE = 30;

const handleSize = size => `
  width: ${size}px;
  height: ${size}px;
`;

const CloseContainer = styled.div`
  display: flex;
  position: ${({ isEmbed }) => (isEmbed ? 'absolute' : 'fixed')};
  z-index: 1;

  // Default values
  margin: 8px;
  inset-inline-end: 0;
  top: 0;

  ${({ horizontalAlignment }) =>
    horizontalAlignment === 'leading' &&
    css`
      inset-inline-start: 0;
      inset-inline-end: unset;
    `}
`;

const CloseWrapper = styled.div`
  &[data-selected='true'] {
    outline: 2px solid var(--color-blue-500);
    outline-offset: 4px;
  }
`;

const CloseButton = styled.button`
  color: #627293;
  background: #edf2fad9;
  border: none;
  border-radius: 50%;
  cursor: pointer;

  ${({ size }) => handleSize(size)}

  ${({ customStyle }) => customStyle}

  svg {
    height: ${({ size }) => size && `calc(${size}px * 0.6)`};
  }

  ${({ buttonAppearance }) =>
    buttonAppearance === SKIPPABLE_OPTIONS.MINIMAL &&
    `
    background: transparent;
    border-radius: 0;
  `}
`;

const CloseX = ({ skippable, isEmbed, colorMode, selectedTrait, onClick }) => {
  const { buttonStyle, buttonAppearance } = skippable ?? {};
  const { horizontalAlignment, ...iconStyles } = buttonStyle ?? {};

  const styles = transformStyles(iconStyles, colorMode);
  const { color, backgroundColor, ...alignmentStyles } = styles ?? {};

  const closeIcon =
    buttonAppearance === SKIPPABLE_OPTIONS.MINIMAL ? ThinIcon : RegularIcon;

  const isSelected = selectedTrait === SKIPPABLE;

  const handleClick = () => {
    if (!isSelected) {
      onClick(SKIPPABLE);
    }
  };

  return skippable && buttonAppearance !== SKIPPABLE_OPTIONS.HIDDEN ? (
    <CloseContainer
      id="close-container"
      style={alignmentStyles}
      horizontalAlignment={horizontalAlignment}
      isEmbed={isEmbed}
    >
      <CloseWrapper
        data-selected={isSelected}
        onClick={handleClick}
        size={buttonStyle?.width ?? DEFAULT_SIZE}
      >
        <CloseButton
          aria-label="Close modal"
          customStyle={{
            color,
            backgroundColor,
          }}
          buttonAppearance={buttonAppearance}
          size={buttonStyle?.width ?? DEFAULT_SIZE}
        >
          <Icon icon={closeIcon} />
        </CloseButton>
      </CloseWrapper>
    </CloseContainer>
  ) : null;
};

CloseX.propTypes = {
  skippable: SkippableShape,
  isEmbed: PropTypes.bool,
  colorMode: PropTypes.oneOf(COLOR_MODES),
  selectedTrait: PropTypes.string,
  onClick: PropTypes.func,
};

const mapStateToProps = state => {
  const { trait: selectedTrait } = selectSelected(state) ?? {};
  return { selectedTrait };
};

const mapDispatchToProps = {
  onClick: focus,
};

export default connect(mapStateToProps, mapDispatchToProps)(CloseX);
