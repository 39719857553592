import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { EditorShape } from 'entities/step-groups';
import { ContentShape } from 'entities/step-children';
import { Shape as TraitsShape } from 'entities/trait';
import { Shape as UserPreferencesShape } from 'entities/user-preferences';
import { ScreensListShape, ScreenLayoutShape } from 'entities/screens';
import { DIRECTIONS, ORIENTATIONS, COLOR_MODES } from 'lib/user-preferences';
import { PLATFORMS } from 'lib/platform';
import { getDevice } from 'lib/device';
import {
  getTargetElementSelector,
  BACKDROP_KEYHOLE,
  TARGET_RECTANGLE,
  TARGET_ELEMENT,
} from 'lib/trait';
import { ANCHORED, FLOATING } from 'lib/presentation';
import { parseScreenSelectors } from 'lib/parse-screen-selectors';
import useWindowResize from 'hooks/use-window-resize';
import useContentScale from 'hooks/use-content-scale';
import TraitNode from 'components/Editor/TraitNode';
import ContentNode from 'components/Editor/ContentNode';
import { transformStyles } from 'components/Editor/Primitives';
import { localizeContent, DEFAULT_LOCALE } from 'lib/localization';
import BackdropKeyhole from './BackdropKeyhole';
import EffectsLayer from './EffectsLayer';
import { TargetPosition } from './TargetPosition';
import { TargetElement } from './TargetElement';
import { DeviceViewport, DeviceOverlay } from './styled';
import { handleInsets } from './handle-insets';

const [LIGHT] = COLOR_MODES;
const [PORTRAIT] = ORIENTATIONS;
const [LTR] = DIRECTIONS;

const Device = forwardRef(
  (
    {
      editorPane,
      editor,
      experienceId,
      content,
      traits,
      screensList,
      selectedScreenLayout,
      platform,
      userPreferences,
      targetPlacement,
      isImageService,
      onTargetPlacementUpdate,
      handleTraitsUpdate,
      onDrop,
    },
    $ref
  ) => {
    const { backdrop, screen, presentation, effects } = editor ?? {};
    const isAnchored = presentation === ANCHORED;
    const isFloating = presentation === FLOATING;
    const selectedScreen = screensList?.[screen?.id];
    const hasScreen = Boolean(selectedScreen);
    const { metadata, presignedScreenshotImageUrl } = selectedScreen ?? {};
    const selectors = parseScreenSelectors(selectedScreenLayout);
    const hasSelectors = selectors.length > 0;
    const hasSelector = hasSelectors && !!getTargetElementSelector(traits);
    const insets = handleInsets(metadata);

    const {
      colorMode = LIGHT,
      viewport,
      orientation = PORTRAIT,
      direction = LTR,
      locale,
    } = userPreferences ?? {};

    const { id: selectedLocaleId } =
      (locale?.experienceId === experienceId && locale) || DEFAULT_LOCALE;

    const targetArea = traits?.find(
      ({ type }) => type === TARGET_ELEMENT || type === TARGET_RECTANGLE
    );

    const backdropKeyhole = traits?.find(
      ({ type }) => type === BACKDROP_KEYHOLE
    );

    const device = getDevice(platform, viewport) ?? {};
    const { value: deviceViewport } = device;

    // If there's a screen selected, use its metadata to determine the device size
    // otherwise, use the viewport from the user preferences
    const [deviceWidth, deviceHeight] = metadata
      ? [metadata.deviceWidth, metadata.deviceHeight]
      : orientation === PORTRAIT
      ? deviceViewport.split('x')
      : deviceViewport.split('x').reverse();

    const deviceSize = {
      width: Number(deviceWidth),
      height: Number(deviceHeight),
    };

    const [scale, setScale] = useContentScale(editorPane, deviceSize);
    useWindowResize(setScale);

    const handleTargetPlacement = isAnchored ? (
      <TargetElement
        targetElement={targetArea}
        selectors={selectors}
        handleTraitsUpdate={handleTraitsUpdate}
        onTargetPlacementChange={onTargetPlacementUpdate}
      />
    ) : (
      <TargetPosition
        targetRectangle={targetArea}
        deviceSize={deviceSize}
        insets={{ ...insets, platform }}
        handleTraitsUpdate={handleTraitsUpdate}
        onTargetPlacementChange={onTargetPlacementUpdate}
      />
    );

    return (
      <DeviceViewport
        ref={$ref}
        aria-label="Device Viewport"
        orientation={orientation}
        dir={direction}
        colorMode={colorMode}
        width={deviceWidth}
        height={deviceHeight}
        scale={scale}
        setOverflow={!targetPlacement}
        background={presignedScreenshotImageUrl?.presignedUrl}
      >
        {targetPlacement ? (
          handleTargetPlacement
        ) : (
          <>
            <EffectsLayer
              effects={effects}
              width={deviceWidth}
              height={deviceHeight}
            />
            <DeviceOverlay aria-label="Device Overlay">
              <TraitNode
                content={content}
                editor={editor}
                colorMode={colorMode}
                orientation={orientation}
                device={device}
                targetArea={targetArea}
                insets={{ ...insets, platform }}
                isAnchored={isAnchored}
                onDrop={onDrop}
                deviceSize={deviceSize}
              >
                <ContentNode
                  {...localizeContent(content, selectedLocaleId)} // eslint-disable-line @appcues/jsx-props-no-spreading
                  colorMode={colorMode}
                  onDrop={onDrop}
                />
              </TraitNode>
            </DeviceOverlay>

            <BackdropKeyhole
              backdrop={transformStyles(backdrop, colorMode)}
              backdropKeyhole={backdropKeyhole}
              deviceSize={deviceSize}
              targetArea={targetArea}
              insets={{ ...insets, platform }}
              isAnchored={isAnchored}
              isFloating={isFloating}
              isImageService={isImageService}
              hasScreen={hasScreen}
              hasSelector={hasSelector}
              hasSelectors={hasSelectors}
              onTargetPlacementChange={onTargetPlacementUpdate}
            />
          </>
        )}
      </DeviceViewport>
    );
  }
);

Device.propTypes = {
  editor: EditorShape,
  isImageService: PropTypes.bool,
  editorPane: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  experienceId: PropTypes.string,
  content: ContentShape,
  traits: TraitsShape,
  screensList: ScreensListShape,
  selectedScreenLayout: ScreenLayoutShape,
  platform: PropTypes.oneOf(PLATFORMS),
  userPreferences: UserPreferencesShape,
  targetPlacement: PropTypes.bool,
  onTargetPlacementUpdate: PropTypes.func,
  handleTraitsUpdate: PropTypes.func,
  onDrop: PropTypes.func,
};

export default Device;
