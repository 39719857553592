import styled from 'styled-components';
import { Spinner, Stack, SubTitle, Text } from '@appcues/sonar';
import { EmptyIcon } from './EmptyIcon';

const Wrapper = styled(Stack)`
  text-align: center;
  margin: var(--spacing-regular) auto;
`;

type EmptyStateType = {
  isLoading: boolean;
};

export const EmptyState = ({ isLoading }: EmptyStateType) => {
  return (
    <Wrapper xAlign="center" yAlign="center" spacing="regular">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <EmptyIcon />
          <SubTitle>Empty Gallery</SubTitle>
          <Text>
            Once you upload images to any Appcues experience, <br />
            you will see them appear here.
          </Text>
        </>
      )}
    </Wrapper>
  );
};
