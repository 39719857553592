import React from 'react';
import styled from 'styled-components';
import { Icon } from '@appcues/sonar';

const EditButton = styled.button.attrs(({ icon }) => ({
  children: <Icon icon={icon} size="small" />,
}))`
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: var(--color-neutral-0);
  position: absolute;
  bottom: 10px;
  right: 4px;
  width: 28px;
  height: 28px;
  background: var(--color-blue-600);
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: var(--color-blue-700);
  }
`;

export default EditButton;
