import { validateDimension } from 'components/validators';

export const validateFileDimension = async (
  file: File,
  maxDimension?: number
): Promise<boolean> => {
  if (!maxDimension) return true;

  try {
    const bitmap = await createImageBitmap(file);
    const isValid = validateDimension(bitmap, maxDimension);
    bitmap.close();

    return isValid;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to create image bitmap:', error);
    return false;
  }
};
