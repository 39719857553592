import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { BlockContentShape } from 'entities/block';
import { ColorShape } from 'entities/step-children';
import { COLOR_MODES } from 'lib/user-preferences';
import { OPTION_SELECT, BLOCK_MODES } from 'lib/block';
import { TextContainer } from 'components/Editor/Containers';
import { transformStyles } from 'components/Editor/Primitives';
import handleErrorStyle from './error-style-handler';

const [, MULTI] = BLOCK_MODES[OPTION_SELECT];

const SelectOptionContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0px;
  width: fit-content;
`;

const Indicator = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  outline: 2px;
  outline-style: solid;
  outline-offset: 3px;
  margin-inline-end: 16px;

  ${({ selectMode }) =>
    selectMode === MULTI &&
    css`
      height: 16px;
      width: 16px;
      border-radius: 1px;
      outline-offset: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    `}

  ${({ selectMode, showSelected }) =>
    selectMode === MULTI &&
    showSelected &&
    css`
      &:before {
        content: '✓';
        color: var(--white);
        font-weight: var(--semi-bold);
        line-height: 16px;
      }
    `}
`;

const SelectOption = ({
  index,
  content,
  selectMode,
  selectedColor,
  unselectedColor,
  previewSelectedColor,
  error,
  colorMode,
}) => {
  const { id: textId, text, style: textStyle } = content;

  const showSelected = index === 0 && previewSelectedColor;

  const selectedIndicatorStyle = {
    outlineColor: selectedColor,
    backgroundColor: selectedColor,
  };

  const unselectedIndicatorStyle = {
    outlineColor: unselectedColor,
    ...(showSelected && selectedIndicatorStyle),
  };

  const baseIndicatorStyle = transformStyles(
    unselectedIndicatorStyle,
    colorMode
  );

  const { previewErrorColor, errorColor } = error;
  const indicatorStyles = previewErrorColor
    ? handleErrorStyle({ key: 'outlineColor', color: errorColor })
    : baseIndicatorStyle;

  return (
    <SelectOptionContainer>
      <Indicator
        selectMode={selectMode}
        showSelected={showSelected}
        style={indicatorStyles}
      />
      <TextContainer
        id={textId}
        text={text}
        style={textStyle}
        colorMode={colorMode}
        readOnly
      />
    </SelectOptionContainer>
  );
};

SelectOption.propTypes = {
  index: PropTypes.number,
  content: BlockContentShape,
  selectMode: PropTypes.oneOf(BLOCK_MODES[OPTION_SELECT]),
  selectedColor: ColorShape,
  unselectedColor: ColorShape,
  previewSelectedColor: PropTypes.bool,
  error: PropTypes.shape({
    previewErrorColor: PropTypes.bool,
    errorColor: PropTypes.string,
  }),
  colorMode: PropTypes.oneOf(COLOR_MODES),
};

export default SelectOption;
