import { v4 as uuid } from 'uuid';
import { BLOCK, IMAGE, IMAGE_WITH_TEXT, STACK, TEXT, SPACER } from 'lib/block';

export const DEFAULT_ICON_URL =
  'https://images.appcues.com/v1660836995/103523/roh2p8ba1mtwtzganmad.png';

export const imageWithTextDescriptionTemplate = () => ({
  type: TEXT,
  id: uuid(),
  text: 'Now you can customize your experience!',
  style: {
    foregroundColor: { light: '#000000' },
    textAlignment: 'leading',
  },
});

const imageWithTextTemplate = ({ blockId, elementId }) => ({
  type: BLOCK,
  blockType: IMAGE_WITH_TEXT,
  id: blockId,
  content: {
    type: STACK,
    id: elementId,
    orientation: 'horizontal',
    distribution: 'center',
    style: {
      horizontalAlignment: 'leading',
      verticalAlignment: 'center',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeading: 16,
      paddingTrailing: 16,
    },
    items: [
      {
        type: STACK,
        id: uuid(),
        orientation: 'vertical',
        style: {
          // This alignment is supported by the Android SDK,
          // making this block retro-compatible with older versions of the SDK
          verticalAlignment: 'top',
        },
        items: [
          {
            type: IMAGE,
            id: uuid(),
            imageUrl: DEFAULT_ICON_URL,
            contentMode: 'fit',
            intrinsicSize: {
              width: 112,
              height: 112,
            },
            style: {
              width: 56,
              marginTrailing: 8,
            },
          },
          // This is used to fill the space in the stack
          // to align the image to the top in the SDK
          {
            type: SPACER,
            id: uuid(),
            spacing: 0,
          },
        ],
      },
      {
        type: STACK,
        id: uuid(),
        orientation: 'vertical',
        style: {
          horizontalAlignment: 'leading',
        },
        items: [
          {
            type: TEXT,
            id: uuid(),
            text: 'New feature',
            style: {
              fontName: 'System Default Semibold',
              foregroundColor: { light: '#000000' },
              textAlignment: 'leading',
            },
          },
          imageWithTextDescriptionTemplate(),
        ],
      },
    ],
  },
});

export default imageWithTextTemplate;
