import { useState, useMemo, useCallback } from 'react';
import { Context } from 'context';
import type { ProviderType, ImageType } from 'types';

const convertFileToBase64 = (file: File) => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', function handleFileLoad(event) {
      const fileData = event.target?.result;
      resolve({
        fileName: file.name,
        fileType: file.type,
        fileData,
      });
    });
    reader.readAsDataURL(file);
  });
};

type CustomResponse = {
  ok: boolean;
  json: () => Promise<any>;
};

const handleApiCall = async (
  apiCall: Promise<any>,
  isOverrideApi = false
): Promise<CustomResponse | Response> => {
  try {
    if (isOverrideApi) {
      const result = await apiCall;
      return {
        ok: true,
        json: () => Promise.resolve(result),
      };
    }
    return await apiCall;
  } catch (error) {
    return {
      ok: false,
      json: () => Promise.reject(error),
    };
  }
};

export const Provider = ({
  children,
  customerApiURL,
  jwt,
  accountId,
  fetchImagesApiOverride,
  uploadImageApiOverride,
}: ProviderType) => {
  const [images, setImages] = useState<ImageType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [pageInfo, setPageInfo] = useState({ number: 1, size: 50, total: 0 });

  const { size } = pageInfo;

  const fetchImages = useCallback(
    async (number = 1) => {
      try {
        setIsLoading(true);

        const response = await (fetchImagesApiOverride
          ? handleApiCall(fetchImagesApiOverride({ number, size }), true)
          : handleApiCall(
              fetch(
                `${customerApiURL}/v1/accounts/${accountId}/images?sort_key=created_at&sort_direction=desc&page_number=${number}&page_size=${size}`,
                {
                  headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json',
                  },
                }
              )
            ));

        if (!response.ok) throw new Error('Failed to fetch images');

        const { data, pageInfo: pageInfoResponse } = await response.json();
        const { number: numberResponse, total: totalResponse } =
          pageInfoResponse;

        setImages(prev => (number === 1 ? data : [...prev, ...data]));
        setPageInfo({
          number: numberResponse,
          size,
          total: totalResponse,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching images:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [jwt, customerApiURL, accountId, size, fetchImagesApiOverride]
  );

  const uploadImage = useCallback(
    async (originalFile: File): Promise<ImageType | null> => {
      try {
        const data = new FormData();
        data.append('file', originalFile);
        const fileData = await convertFileToBase64(originalFile);

        const response = await (uploadImageApiOverride
          ? handleApiCall(uploadImageApiOverride(fileData), true)
          : handleApiCall(
              fetch(
                `${customerApiURL}/v1/accounts/${accountId}/images/upload`,
                {
                  method: 'POST',
                  body: data,
                  headers: {
                    Authorization: `Bearer ${jwt}`,
                  },
                }
              )
            ));

        if (!response.ok) {
          throw new Error(`Upload failed`);
        }

        const result = await response.json();
        return result;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Image upload failed:', error);
        throw error;
      }
    },
    [customerApiURL, accountId, jwt, uploadImageApiOverride]
  );

  const value = useMemo(
    () => ({
      images,
      setImages,
      isLoading,
      setIsLoading,
      isGalleryOpen,
      setIsGalleryOpen,
      pageInfo,
      setPageInfo,
      fetchImages,
      uploadImage,
    }),
    [images, isLoading, isGalleryOpen, pageInfo, uploadImage, fetchImages]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
