import styled, { css } from 'styled-components';
import { Icon } from '@appcues/sonar';

export const CustomComponentPlaceholder = styled.div`
  ${({ isSideBySide }) =>
    isSideBySide
      ? css`
          width: 100%;

          &:after {
            content: '';
            position: absolute;
            width: 25px;
            height: 100%;
            top: 50%;
            transform: translateY(-50%);
            inset-inline-end: 0;
            border: 1px dashed var(--color-blue-500);
            background: var(--color-blue-100-opacity-50);
          }
        `
      : css`
          width: calc(100% - 2px);
          height: 64px;
          margin: 1px;
          border: 1px dashed var(--color-blue-500);
          background: var(--color-blue-100-opacity-50);
        `}
`;

export const DividerWrapper = styled.div`
  width: 100%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};

  ${({ hideDividerLine }) =>
    !hideDividerLine &&
    `
    outline: 1px solid var(--color-blue-500);
  `}
`;

export const Divider = styled.div`
  z-index: 2;
  position: absolute;
  width: 100%;
  margin-top: -13px;

  ${({ position }) =>
    position === 'right' &&
    css`
      width: 25px;
      height: 100%;
      top: 50%;
      transform: translateY(-50%);
      inset-inline-end: 0;
      margin: 0;

      &:before {
        content: '';
        border: none;
        position: absolute;
        width: 0;
        height: 100%;
        background: var(--color-blue-100-opacity-50);
        margin-left: 3px;
        transition: width 0.2s ease-in-out;
      }

      &:hover,
      &[data-content-menu-open='true'] {
        &:before {
          width: 32px;
          border: 1px dashed var(--color-blue-500);
        }
      }
    `}
`;

const BUTTON_SIZE = 20;

export const DividerBackground = styled.div`
  height: 0;
  border: none;
  width: 100%;
  background: var(--color-blue-100-opacity-50);
  margin-top: 12px;
  transition: height 0.2s ease-in-out, transform 0.2s ease-in-out;
`;

export const AddButton = styled.button`
  z-index: 2;
  position: absolute;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  right: calc(50% - ${BUTTON_SIZE / 2}px);
  height: ${BUTTON_SIZE}px;
  width: ${BUTTON_SIZE}px;
  font-size: 18px;
  color: var(--color-neutral-0);
  background: var(--color-blue-600);
  border-radius: 50%;
  margin-top: -${BUTTON_SIZE / 2}px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  ${({ position, isFirstRow, isLastRow }) => css`
    ${position === 'right' &&
    `
    right: 10px;
    top: 50%;
    `}

    ${position === 'top' &&
    isFirstRow &&
    `
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 1px;
    `};

    ${position === 'bottom' &&
    isLastRow &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: -${BUTTON_SIZE - 1}px;
    `};
  `}

  ${Icon} {
    width: 10px;
  }

  &:hover,
  &[data-content-menu-open='true'] {
    transform: scale(1.5);
    background: var(--color-blue-700);

    & + ${DividerBackground} {
      height: 38px;
      transform: translateY(-20px);
      border: 1px dashed var(--color-blue-500);
    }
  }
`;

export const DragIndicator = styled.div`
  z-index: 1;
  position: fixed;

  ${({ isAbove }) => `${isAbove ? 'top' : 'bottom'}: -1px;`};

  display: flex;
  align-items: center;
  pointer-events: none;

  > svg {
    color: var(--color-blue-500);
    position: fixed;
    width: 85%;
    left: 7.5%;
  }
`;
