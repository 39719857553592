/* globals CUSTOMER_API_URL */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ImageryProvider } from '@appcues/imagery-kit';
import AnalyticsContext, { createAnalyticsClient } from 'ext/lib/analytics';
import { Shape as AuthShape } from 'lib/auth';
import { Shape as UserShape } from 'entities/user';
import StyleProvider from 'styles/StyleProvider';
import configureStore from './configure-store';
import rootReducer from './root-reducer';
import rootSaga from './root-saga';

export default function Providers({ auth, user, children }) {
  const store = useMemo(() => {
    return configureStore({
      auth,
      user,
      rootReducer,
      rootSaga,
    });
  }, [auth, user]);

  const analyticsClient = createAnalyticsClient(
    store.getState(),
    {
      app: 'Mobile Builder',
    },
    true // client mode
  );

  return (
    <Provider store={store}>
      <AnalyticsContext.Provider value={analyticsClient}>
        <DndProvider backend={HTML5Backend}>
          <StyleProvider>
            <ImageryProvider
              jwt={auth.jwt}
              accountId={auth.accountId}
              customerApiURL={CUSTOMER_API_URL}
            >
              {children}
            </ImageryProvider>
          </StyleProvider>
        </DndProvider>
      </AnalyticsContext.Provider>
    </Provider>
  );
}

Providers.propTypes = {
  auth: AuthShape,
  user: UserShape,
  children: PropTypes.node.isRequired,
};
