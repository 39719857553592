import styled from 'styled-components';
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage';
import { Row, Text, Stack, Icon } from '@appcues/sonar';
import type { ImageType } from 'types';
import { formatDate, formatSize } from './gallery-helpers';

const InvalidMessage = styled.div`
  position: absolute;
  display: none;
  min-width: 210px;
  text-align: center;
  color: var(--foreground-primary);
  background-color: var(--background-default-base);
  padding: var(--spacing-small);
  border: 1px solid var(--border-input-default);
  border-radius: var(--border-radius-small);
`;

const Root = styled.article<{ isDisabled: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 220px;
  min-height: auto;
  background: var(--background-level-1);
  border: 1px solid var(--border-input-default);
  border-radius: var(--border-radius-small);
  overflow: hidden;
  transition: opacity 0.2s, outline 0.2s;

  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};

  &:hover {
    outline: ${({ isDisabled }) =>
      isDisabled ? 'none' : '1px solid var(--border-input-hover)'};

    ${InvalidMessage} {
      display: ${({ isDisabled }) => (isDisabled ? 'block' : 'none')};
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: center;
  background-color: var(--color-neutral-100);
`;

const ImageSource = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
`;

const Title = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
`;

const Footer = styled.footer`
  background-color: var(--color-neutral-200);
  padding: var(--spacing-small);
`;

type CardProps = {
  image: ImageType;
  onClick: (image: ImageType) => void;
  invalidMessage?: string;
};

export const Card = ({ image, onClick, invalidMessage }: CardProps) => {
  const { id, name, url, width, height, size, format, updatedAt } = image;
  const isDisabled = Boolean(invalidMessage);

  return (
    <Root
      key={id}
      onClick={() => (isDisabled ? null : onClick(image))}
      isDisabled={isDisabled}
    >
      <ImageWrapper>
        <ImageSource src={url} />
        {invalidMessage && <InvalidMessage>{invalidMessage}</InvalidMessage>}
      </ImageWrapper>
      <Footer>
        <Stack spacing="x-small">
          <Row fluid xAlign="space-between">
            <Title weight="bold" title={name}>
              {name}
            </Title>
            <Text weight="bold" size="small">
              {formatDate(updatedAt)}
            </Text>
          </Row>
          <Row fluid xAlign="space-between">
            <Row yAlign="center" spacing="x-small">
              <Icon size="x-small" icon={faImage} />
              <Text size="small">
                {width} x {height} / {format.toUpperCase()}
              </Text>
            </Row>
            <Text size="small">{formatSize(size)}</Text>
          </Row>
        </Stack>
      </Footer>
    </Root>
  );
};
